import { styled } from "styled-components";
import getImageURL from "../../../../../zekFrontendToolkit/mediaUpload/controller/getImageURL";
import ProfilePicture from "../../../../../util/components/ProfilePicture";

const Container = styled.div`
  width: 100%;
  background-color: var(--secondarySurface);
  padding: 15px;
  position: relative;
  border-radius: 10px;
  font-size: 13px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

const ReplyingToImage = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 5px;
`;

const ReplyingToText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ReplyingToUser = styled.div`
  text-transform: capitalize;
  font-weight: 700;
`;

const ReplyingToMsg = styled.div``;

export default function ReplyOfBox({ item }) {
  if (!item) return null;

  let text = "";
  let image = item.message.image;

  if (image) {
    text = "Photo";
  } else {
    text = item.message.text;
  }

  return (
    <Container>
      {image && <ProfilePicture size={50} data={image}></ProfilePicture>}
      <ReplyingToText>
        <ReplyingToUser>{item.messageAuthor.username}</ReplyingToUser>
        <ReplyingToMsg> {text} </ReplyingToMsg>
      </ReplyingToText>
    </Container>
  );
}

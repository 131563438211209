import { Capacitor } from '@capacitor/core';

export default function getLinkToS3(fileName) {
  let environment = 'production';

  if (window.location.host.indexOf('localhost') !== -1) {
    environment = 'development';
  }

  if (window.location.host.indexOf('192.168') !== -1) {
    environment = 'development';
  }

  let platform = Capacitor.getPlatform();

  if (platform !== 'web') {
    let isDev = window.location.href.startsWith('http://');

    if (isDev) {
      environment = 'development';
    } else {
      environment = 'production';
    }
  }

  let base = 'http://simple-and-nice.s3.ap-south-1.amazonaws.com';

  base = 'https://d3agq5fmkvjuh7.cloudfront.net';

  return `${base}/${environment}/${window.zekToolkitConfig.namespace}/${fileName}`;
}

import CustomMasonry from "../CustomMasonry";
import useZekToolkit from "../../../zekFrontendToolkit/useZekToolkit";

import styled, { keyframes } from "styled-components";
import ShimmerLightMode from "./ShimmerLightMode";
import ShimmerDarkMode from "./ShimmerDarkMode";

const shimmerAnimation = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

// Create the styled component with shimmer effect
const ShimmerContainer = styled.div`
  width: 300px;
  height: 200px;
  background: #f6f7f8;
  border-radius: 4px;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgb(214 223 245) 0%,
      rgb(234 240 255) 50%,
      rgb(213 222 245) 100%
    );
    background-size: 200% 100%;
    animation: ${shimmerAnimation} 1.5s infinite linear;
  }
`;

const ShimmerContainerDark = styled.div`
  width: 300px;
  height: 200px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgb(0, 0, 0) 0%,
      rgb(31, 31, 31) 50%,
      rgb(0, 0, 0) 100%
    );
    background-size: 200% 100%;
    animation: ${shimmerAnimation} 1.5s infinite linear;
  }
`;

export default function MasonryShimmer() {
  const { isMobile, colorMode } = useZekToolkit();

  let totalItems = 20;

  let items = [];

  let Comp = ShimmerLightMode;

  if (colorMode == "DARK") Comp = ShimmerDarkMode;

  for (let i = 0; i < totalItems; i++) {
    items.push(
      <Comp
        style={{
          borderRadius: "20px",
          width: isMobile ? "90vw" : "100%",
          height: "150px",
        }}
        // height={300}
        // width={isMobile ? mobileWidth : desktopWidth}
      />
    );
  }

  return (
    <CustomMasonry
      disableAnimation={true}
      colsOnMobile={1}
      maxCol={2}
      list={items}
    />
  );
}

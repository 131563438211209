function getServerURL() {
  if (window.location.href.indexOf("192.168") !== -1) {
    // return "http://10.0.2.2:8080";
    return "http://192.168.29.21:8080"; // For running the webpage locally on the phone.
  }

  if (window.location.href.indexOf("localhost:") !== -1) {
    // return "http://10.0.2.2:8080";
    return "http://192.168.29.21:8080"; // For running the webpage locally on the phone.
  }

  let base = "https://cottonchat.com";

  return base;
}

export default getServerURL;

import universalLocalStorage from "../universalLocalStorage.js";

class ServerLine {
  constructor() {}

  get(route) {
    return this.executer(route);
  }

  post(route, body) {
    return this.executer(route, body, "POST");
  }

  delete(route) {
    return this.executer(route, null, "DELETE");
  }

  patch(route, body) {
    return this.executer(route, body, "PATCH");
  }

  getServerURL() {
    let type = this.getEnvType();

    let serverlineConfig = window.serverlineConfig;

    if (this.forceProductionMode) return serverlineConfig.productionServerURL;
    if (type == "DEV") {
      return serverlineConfig.localServerURL;
    }
    return serverlineConfig.productionServerURL;
  }

  getEnvType() {
    if (window.location.href.indexOf("localhost") !== -1) return "DEV";
    return "PRODUCTION";
  }

  async executer(route, body, method) {
    // throw Error("User not found");

    let requestType = "GET";
    if (body) requestType = "POST";
    if (method) requestType = method;

    let namespace = window.serverlineConfig.namespace;

    let authToken = await universalLocalStorage.getItem(namespace + "-token");

    let headerParam = {
      authorization: authToken ? JSON.stringify({ authToken }) : null,
      "Content-type": "application/json",
    };

    let requestObject = {
      mode: "cors",
      method: requestType,
      headers: headerParam,
    };

    if (body) requestObject.body = JSON.stringify(body);

    // console.log(process.env);

    if (route.indexOf("http:") !== -1) {
      //This is an absolute url
    } else {
      if (route[0] !== "/")
        throw Error("ServerLine route should start with / ");

      let base = this.getServerURL();

      // console.log(base, "base");

      route = base + "/api/v1" + route;
    }

    // console.log(route);
    // console.log(route);

    let res = await fetch(route, requestObject);

    let jsonData = await res.json();

    if (jsonData.error) {
      if (jsonData.error === "Invalid user") {
        window.zekToolkitFunc.logout();
      } else if (jsonData.error === "Login Required") {
        //   openLoginPopup();
        window.alert("Login required!!");
        throw Error(jsonData.error);
      } else {
        throw Error(jsonData.error);
      }
    }
    return jsonData.data;
  }
}

let serverLine = new ServerLine();

export default serverLine;

import { styled } from "styled-components";
import { useEffect, useState } from "react";

import FeedItemDiscussion from "../../util/components/feedItem/FeedItemDiscussion";
import UserBox from "../profilePage/UserBox";
import LoadingSection from "../../util/components/LoadingSection";
import CustomToggle from "../../util/components/inputs/CustomToggle";
import MaterialInput from "../../util/components/MaterialInput";
import InfoBox from "../../util/components/InfoBox";
import FeedItemConclusion from "../../util/components/feedItem/FeedItemConclusion";
import serverLine from "../../zekFrontendToolkit/network/serverLine";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 0;
  height: 100%;
  width: 100%;
  align-items: center;
  overflow-y: scroll;
`;

const Main = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  width: 38vw;
  gap: 40px;
  align-items: center;
  margin-bottom: 50px;

  @media (max-width: 900px) {
    width: 100%;
    margin-top: 0;
    gap: 15px;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 38vw;
  margin-top: 40px;

  @media (max-width: 900px) {
    width: calc(100vw - 40px);
  }
`;

let typeOptions = [
  { label: "Content", value: "CONTENT" },
  { label: "Profiles", value: "PROFILE" },
  { label: "Conclusions", value: "CONCLUSION" },
];

export default function SearchPage() {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState(null);
  const [type, setType] = useState("CONTENT");

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    doQuery();
  }, [type]);

  async function doQuery() {
    if (searchQuery) {
      setLoading(true);
      setList([]);
      let res = await serverLine.get(
        `/search/?&type=${type}&query=${searchQuery}`
      );
      setList(res);
      setLoading(false);
    }
  }

  let topPart = (
    <Top>
      <MaterialInput
        style={{ background: "var(--primarySurface)" }}
        label={"Search"}
        value={searchQuery}
        onEnter={doQuery}
        onChange={updateSearchQuery}
      />

      <CustomToggle
        colorVariation={"SURFACE"}
        value={type}
        onChange={(newType) => {
          setList([]);
          setType(newType);
        }}
        options={typeOptions}
      />
    </Top>
  );

  if (loading)
    return (
      <Container>
        {topPart}
        <LoadingSection />
      </Container>
    );

  if (!list) {
    // when nothing has been typed
    return <Container>{topPart}</Container>;
  }

  let items = [];

  if (type == "PROFILE") {
    items = list.map((item) => <UserBox item={item} />);
  } else if (type == "CONCLUSION") {
    items = list.map((item) => <FeedItemConclusion item={item} />);
  } else {
    items = list.map((item) => <FeedItemDiscussion item={item} />);
  }

  let content = <List>{items}</List>;

  if (!list.length) {
    if (searchQuery) {
      content = <InfoBox>Nothing found</InfoBox>;
    } else {
      content = null;
    }
  }

  return (
    <Container>
      {topPart}
      <Main>{content}</Main>
    </Container>
  );

  function updateSearchQuery(e) {
    setSearchQuery(e.target.value);
  }
}

import { styled } from "styled-components";
import { AiOutlineSend } from "react-icons/ai";
import { useContext, useEffect, useState } from "react";

import LoadingSection from "../../../../util/components/LoadingSection";

import MaterialInput from "../../../../util/components/MaterialInput";

import FilledButton from "../../../../util/components/buttons/FilledButton";

import FeedItemConclusion from "../../../../util/components/feedItem/FeedItemConclusion";
import serverLine from "../../../../zekFrontendToolkit/network/serverLine";
import Context from "../../../../Context";
import { heIL } from "@mui/x-date-pickers";
import { BiImage } from "react-icons/bi";
import compressAndUploadFile from "../../../../zekFrontendToolkit/mediaUpload/controller/compressAndUploadFile";
import selectFile from "../../../../zekFrontendToolkit/mediaUpload/controller/selectFile";
import LoadingSpinner from "../../../../zekFrontendToolkit/ui/LoadingSpinner";
import getImageURL from "../../../../zekFrontendToolkit/mediaUpload/controller/getImageURL";
import ShowSelectedImage from "../../../../util/components/ShowSelectedImage";

const EditingPart = styled.div`
  /* height: 100px; */
  width: 100%;

  align-items: flex-end;
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const TheImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 600px;
  object-fit: cover;
  border-radius: 15px;
`;

export default function ConclusionEditor({
  itemID,
  initialText,
  initialImage,
  onDone,
}) {
  const { globalChatSpace } = useContext(Context);
  const { selectedChatGroupID } = globalChatSpace;
  const [text, setText] = useState(initialText);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);

  if (loading) return <LoadingSpinner />;

  let theImage = null;

  if (initialImage) {
    theImage = <TheImage src={getImageURL(initialImage)} />;
  }

  if (selectedImage) {
    theImage = (
      <ShowSelectedImage
        width="100%"
        height="auto"
        borderRadius="10px"
        fileObject={selectedImage}
      />
    );
  }

  let editingPart = (
    <>
      {theImage}
      <MaterialInput
        label={"Type your conclusion Here"}
        onChange={updateText}
        value={text}
        rows={6}
        maxRows={12}
        multiline={true}
      />

      <Buttons>
        <FilledButton
          style={{ height: "50px" }}
          variant="filled"
          icon={<BiImage />}
          onClick={doImageSelection}
        ></FilledButton>

        <FilledButton
          style={{ height: "50px" }}
          variant="filled"
          onClick={postConclusions}
          icon={<AiOutlineSend />}
        >
          Publish Conclusion
        </FilledButton>
      </Buttons>
    </>
  );

  return <EditingPart>{editingPart}</EditingPart>;

  async function doImageSelection() {
    let images = await selectFile({ onlyImage: true });

    if (images) {
      if (images[0]) {
        setSelectedImage(images[0]);
      }
    }
  }

  function updateText(e) {
    setText(e.target.value);
  }

  async function postConclusions() {
    if (!text) return window.popupAlert("text can't be empty");
    if (!text.trim()) return window.popupAlert("text can't be empty");

    try {
      setLoading(true);

      let theImage = initialImage;

      let toSend = { text };

      if (selectedImage) {
        let imageData = await compressAndUploadFile({
          selectedFile: selectedImage,
          folder: "conclusion",
        });

        theImage = { type: "S3_UPLOAD", data: imageData.fileName };
      }

      if (theImage) {
        toSend.images = [theImage];
      }

      if (itemID) {
        await serverLine.patch("/conclusion", {
          conclusionID: itemID,
          changes: {
            data: toSend,
          },
        });

        window.goTo("/conclusion/" + itemID)();
      } else {
        await serverLine.post("/conclusion", {
          chatGroupID: selectedChatGroupID,
          data: toSend,
        });
      }

      if (onDone) onDone();
      setText("");
      setLoading(false);
    } catch (e) {
      console.warn(e);
      setLoading(false);
      window.popupAlert(e.message);
    }
  }
}

import styled from "styled-components";
import { useContext, useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";

const MultiColumn = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  display: grid;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 48%; */
  flex: 1;
`;

export default function CustomMasonry({
  list,
  maxCol,
  gap,
  colsOnMobile,
  disableAnimation,
}) {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setInnerWidth(window.innerWidth);
    });
  }, []);

  let numberOfSplits = 2;

  if (innerWidth > 900 && maxCol > 2) numberOfSplits = maxCol;

  if (innerWidth <= 900 && colsOnMobile) numberOfSplits = colsOnMobile;

  let split = doSplit(numberOfSplits);
  let toRender = [];

  if (!gap) {
    gap = "15px";
    if (innerWidth < 900) {
      gap = "10px";
    }
  }

  let i = 0;
  for (let item of split) {
    let subList = [];

    let j = 0;
    for (let subItem of item) {
      if (disableAnimation) {
        subList.push(subItem);
      } else {
        subList.push(
          <motion.div
            layout
            key={j}
            layoutId={j}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.4, delay: j * 0.1 }}
            className="p-2 bg-gray-200 rounded"
          >
            {subItem}
          </motion.div>
        );
      }

      j++;
    }

    if (disableAnimation) {
      toRender.push(
        <Col style={{ gap }} key={i}>
          {subList}
        </Col>
      );
    } else {
      toRender.push(
        <Col style={{ gap }} key={i}>
          <AnimatePresence>{subList}</AnimatePresence>
        </Col>
      );
    }

    i++;
  }

  return (
    <MultiColumn style={{ gridTemplateColumns: getGridStyle(), gap }}>
      {toRender}
    </MultiColumn>
  );

  function getGridStyle() {
    let a = "";

    for (let i = 0; i < numberOfSplits; i++) {
      a += "1fr ";
    }

    return a;
  }

  function doSplit(numberOfSplits) {
    let splits = [];

    let i = 0;
    for (let item of list) {
      let remainder = i % numberOfSplits;
      if (!splits[remainder]) splits[remainder] = [];
      splits[remainder].push(item);
      i++;
    }

    return splits;
  }
}

import styled from "styled-components";
import ChatMessagesPanel from "../chatMessagesPanel/ChatMessagesPanel";
import ChatMessagesPanelHeader from "../chatMessagesPanel/ChatMessagesPanelHeader";
import CustomToggle from "../../../../util/components/inputs/CustomToggle";
import { useContext, useState } from "react";
import ChatMembersSection from "../chatMembersSection/ChatMembersSection";
import ConclusionSection from "../conclusionSection/ConclusionSection";
import LoadingSection from "../../../../util/components/LoadingSection";
import ChatOptionsSection from "../chatOptionsSection/ChatOptionsSection";
import ChatPanelBottomBar from "../chatMessagesPanel/ChatPanelBottomBar";
import Context from "../../../../Context";

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;

  background: var(--primarySurface);
  border-radius: 10px;

  border: none;
  display: flex;
  ${({ $selectedChatGroupID }) => {
    if (!$selectedChatGroupID) return `display: none;`;
  }}
  position:fixed;
  top: 0;
  left: 0;
  height: 100vh;
  height: 100svh;
  width: 100vw;

  backdrop-filter: blur(20px);
  z-index: 500;
`;

export default function MobileChatMainPanel() {
  const { globalChatSpace } = useContext(Context);
  let {
    selectedChatGroupID,
    selectedChatGroupIsLoading,
    selectedChatGroupData,
  } = globalChatSpace;

  const [tab, setTab] = useState("MESSAGES");

  if (selectedChatGroupIsLoading)
    return (
      <Container $selectedChatGroupID={selectedChatGroupID}>
        <LoadingSection />
      </Container>
    );

  if (!selectedChatGroupData) return null;
  //in case access is denied

  return (
    <Container $selectedChatGroupID={selectedChatGroupID}>
      <ChatMessagesPanelHeader tab={tab} setTab={setTab} />
      {renderComponent()}
    </Container>
  );

  function renderComponent() {
    if (tab == "MESSAGES")
      return (
        <>
          <ChatMessagesPanel />
          <ChatPanelBottomBar />
        </>
      );
    if (tab == "CONCLUSIONS") return <ConclusionSection />;
    if (tab == "OPTIONS") return <ChatOptionsSection />;
    if (tab == "MEMBERS") return <ChatMembersSection />;
  }
}

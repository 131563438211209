import CustomMasonry from "../CustomMasonry";
import useZekToolkit from "../../../zekFrontendToolkit/useZekToolkit";

import styled, { keyframes } from "styled-components";
import ShimmerLightMode from "./ShimmerLightMode";
import ShimmerDarkMode from "./ShimmerDarkMode";

export default function ChatGroupsShimmer() {
  const { isMobile, colorMode } = useZekToolkit();

  let totalItems = 6;

  let items = [];

  let Comp = ShimmerLightMode;

  if (colorMode == "DARK") Comp = ShimmerDarkMode;

  for (let i = 0; i < totalItems; i++) {
    items.push(
      <Comp
        style={{
          borderRadius: "20px",
          width: "100%",
          height: "80px",
        }}
        // height={300}
        // width={isMobile ? mobileWidth : desktopWidth}
      ></Comp>
    );
  }

  return items;
}

import { useContext, useState } from "react";
import Context from "../../../Context";
import styled from "styled-components";
import FilledButton from "../buttons/FilledButton";
import BrandContainer from "../brand/BrandContainer";
import { GrGoogle } from "react-icons/gr";
import {
  RiChat3Line,
  RiHomeLine,
  RiMenu3Line,
  RiNotificationLine,
} from "react-icons/ri";
import { HiMenuAlt3, HiOutlineMenuAlt4 } from "react-icons/hi";
import calculateUnreadMessagesCount from "../../../pages/chatPage/controllers/ui/calculateUnreadMessagesCount";

import useZekToolkit from "../../../zekFrontendToolkit/useZekToolkit";
import MobileFloatingHeader from "./MobileFloatingHeader";
import { BiPlus, BiUser } from "react-icons/bi";

const MobileTopNav = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 0;
  background: transparent;
  border: none;
  width: 100%;
  font-family: "Raleway";
  background: var(--primarySurface);
  align-items: center;
  padding: 0 20px;
  height: 55px;
  z-index: 66;
  border-radius: 0 0 5px 5px;
  animation: fadeIn 0.2s ease-out forwards;
  border-bottom: 1px solid var(--mobileHeaderBorder);
`;

const Items = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  padding-bottom: 30px;
  align-items: center;

  flex-direction: column;

  @media (max-width: 900px) {
    flex-direction: row;
    padding: 0 10px;
    gap: 0;
    width: auto;
    justify-content: space-between;
    /* width: 100%; */
  }
`;

const NotificationBubble = styled.div`
  display: flex;
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 10px;
  padding: 0 7px;
  height: 20px;
  font-weight: 900;
  /* top: 15px; */
  padding: 0;
  border-radius: 500px;
  height: 25px;
  padding: 0 10px;
  width: auto;
  background-color: var(--accentColor);
  color: var(--primarySurface);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
`;

const NotificationBubbleAlert = styled(NotificationBubble)`
  /* color: tomato; */
  background-color: tomato;
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  padding: 35px 0;
  align-items: center;

  width: 100%;

  background: var(--primarySurface);
  border-bottom: 1px solid var(--foreground);

  @media (max-width: 900px) {
    background: transparent;
    border: none;
    width: auto;
  }
`;

const NavIconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  animation: centerScaleReveal 0.5s ease-out forwards;
  padding: 10px 10px;
  position: relative;
  border-radius: 10px;
  padding-right: 0;
  /* opacity: 0.5; */
  /* background-color: var(--secondaryInput); */
  /* border: 1px solid var(--translucent); */
  /* background-color: var(--secondaryInput); */

  /* &:hover {
    background-color: var(--primaryInput);
  } */
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

const MobileLogo = styled.div`
  width: 90px;
  height: 50px;
  background: var(--textLogo);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

export default function MobileHeader() {
  const { notificationData } = useContext(Context);
  const { loggedInUserID, doGoogleLogin, loggedInUser } = useZekToolkit();

  const [loginLoading, setLoginLoading] = useState(false);

  if (!loggedInUserID)
    return (
      <MobileTopNav>
        <Logo>
          <BrandContainer />
        </Logo>
        <Items>
          <FilledButton
            style={{ borderRadius: "10px" }}
            variant="filled"
            isLoading={loginLoading}
            onClick={() => {
              doGoogleLogin({ setLoading: setLoginLoading });
            }}
            icon={<GrGoogle />}
          >
            Login / Signup
          </FilledButton>
        </Items>
      </MobileTopNav>
    );

  let unreadMessages = calculateUnreadMessagesCount();

  return (
    <MobileTopNav>
      <MobileFloatingHeader />

      <Logo onClick={window.goTo(`/`)}>
        <MobileLogo />
      </Logo>

      <Buttons>
        <NavIconButton onClick={window.goTo(`/more-menu`)}>
          <RiMenu3Line />
        </NavIconButton>
      </Buttons>
    </MobileTopNav>
  );
}

import { styled } from "styled-components";
import { useState } from "react";
import { MdLightMode } from "react-icons/md";
import { AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import { LiaBanSolid } from "react-icons/lia";
import { BiLock, BiLogOut } from "react-icons/bi";
import { GoMoon, GoReport } from "react-icons/go";
import adminAccess from "../../data/adminAccess";
import LoadingSection from "../../util/components/LoadingSection";
import FilledButton from "../../util/components/buttons/FilledButton";
import { RiNotification2Line, RiSearch2Line, RiUserLine } from "react-icons/ri";
import requestPushNotifPermission from "../../services/firebase/pushNotification/requestPushNotifPermission";
import useZekToolkit from "../../zekFrontendToolkit/useZekToolkit";
import fullLogout from "../../util/controllers/fullLogout";
import AnimatedPage from "../../zekFrontendToolkit/ui/animation/AnimatedPage";
import { AnimatePresence, motion } from "framer-motion";
import AccountSwitcher from "../../util/components/profile/AccountSwitcher";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 50px;
  /* height: 400px; */

  @media (max-width: 900px) {
    margin: 0;
  }
`;
const Title = styled.div`
  font-size: 21px;
  text-align: center;
  font-weight: 300;
`;
const Types = styled.div`
  display: grid;

  grid-template-columns: 1fr;
  gap: 25px;
  justify-content: center;
  align-items: center;
`;

const Message = styled.div`
  margin-top: 200px;
`;

let variant = "filled";

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export default function MoreMenuPage() {
  const { loggedInUser, loggedInUserID, colorMode, switchColorMode, isMobile } =
    useZekToolkit();

  const [loading, setLoading] = useState(false);
  const [loadingLogout, setLoadingLogout] = useState(false);

  let isAdmin = false;

  if (loggedInUser) {
    isAdmin = adminAccess.includes(loggedInUser.me.email);
  }

  if (!loggedInUserID) return <Message>Login to continue</Message>;

  if (loading || !loggedInUser) return <LoadingSection />;

  let style = {
    padding: "15px 50px",
    borderRadius: "20px",
    border: "1px solid var(--primarySurface)",
    boxShadow: "0px 5px 5px 0 rgba(0,0,0,0.05)",
  };

  let mobileButtonList = [
    {
      href: "/create",
      icon: <AiOutlinePlus />,
      label: "Create Discussion",
    },
    {
      href: `/${loggedInUser.me.username}`,
      icon: <RiUserLine />,
      label: "My Profile",
    },
    { href: "/search", icon: <RiSearch2Line />, label: "Search" },
  ];

  let mainButtons = [
    { href: "/edit-profile", icon: <AiOutlineEdit />, label: "Edit Profile" },
    { href: `/blocklist`, icon: <BiLock />, label: "Block List" },
    {
      onClick: requestPushNotifPermission,
      icon: <RiSearch2Line />,
      label: "Enable Push Notifications",
    },
    {
      onClick: switchColorMode,
      icon: colorMode == "DARK" ? <MdLightMode /> : <GoMoon />,
      label: colorMode == "DARK" ? "Light Mode" : "Dark Mode",
    },
    { onClick: doLogout, icon: <BiLogOut />, label: "Logout" },
  ];

  if (isAdmin) {
    mainButtons.push({
      href: "/manage-reported-items",
      icon: <GoReport />,
      label: "Reported Items",
    });
    mainButtons.push({
      href: "/manage-bans",
      icon: <LiaBanSolid />,
      label: "Banned Items",
    });
  }

  if (isMobile) {
    for (let item of mobileButtonList) {
      mainButtons.unshift(item);
    }
  }

  return (
    <AnimatedPage>
      <Container>
        <Menu>
          <Title>More</Title>

          <AnimatePresence>
            <Types>
              <AccountSwitcher
                colorVariation={"SURFACE"}
                style={{ width: "100%" }}
              />
              {mainButtons.map((item, index) => (
                <motion.div
                  layout
                  key={item.label}
                  layoutId={String(item.label)}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                  className="p-2 bg-gray-200 rounded"
                >
                  <FilledButton
                    style={style}
                    colorVariation={"SURFACE"}
                    href={item.href}
                    icon={item.icon}
                    onClick={item.onClick}
                  >
                    {item.label}
                  </FilledButton>
                </motion.div>
              ))}
            </Types>
          </AnimatePresence>
        </Menu>

        <br />
        <br />
        <br />
        <br />
      </Container>
    </AnimatedPage>
  );

  async function doLogout() {
    setLoadingLogout(true);
    fullLogout();
  }
}

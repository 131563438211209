import { useState } from "react";

import styled from "styled-components";
import { BsThreeDotsVertical } from "react-icons/bs";
import openChat from "../../../pages/chatPage/controllers/api/openChat";
import ReportsDataBox from "../ReportsDataBox";
import FilledButton from "../buttons/FilledButton";
import UnderlinedButton from "../buttons/UnderlinedButton";
import Options from "../inputs/Options";

import useZekToolkit from "../../../zekFrontendToolkit/useZekToolkit";
import serverLine from "../../../zekFrontendToolkit/network/serverLine";
import { BiSolidUser, BiUser } from "react-icons/bi";
import ProfilePicture from "../ProfilePicture";
import capitalizeFirstLetter from "../../../zekFrontendToolkit/utils/capitalizeFirstLetter";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
  border-radius: 14px;
  padding: 25px 30px;
  background: var(--primarySurface);
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.08);

  /* animation: centerScaleReveal 0.3s ease-out forwards; */

  @media (max-width: 900px) {
    padding: 15px;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;

  gap: 7px;
`;

const PrimaryLine = styled.div`
  font-size: 19px;
  font-weight: 900;

  @media (max-width: 900px) {
    font-size: 15px;
  }
`;

const Description = styled.div`
  font-size: 17px;
  opacity: 0.8;
  font-weight: 600;

  @media (max-width: 900px) {
    font-size: 14px;
  }
`;

const SecondaryLine = styled.div`
  font-size: 13px;
  display: flex;
  gap: 15px;
  opacity: 0.7;
  flex-direction: row;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  padding-top: 0;
  align-items: center;
  gap: 10px;

  @media (max-width: 900px) {
    gap: 10px;
    /* justify-content: space-between; */
  }
`;

const Info = styled.span`
  text-transform: capitalize;
  font-weight: 600;

  font-size: 13px;

  @media (max-width: 900px) {
    font-size: 11px;
  }
`;

const ForContentPage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export default function FeedItemDiscussion({
  item,
  showReportsData,
  forContentPage,
  ignoreBan,
}) {
  const { popupAlert, loggedInUserID, showPopup, goTo } = useZekToolkit();
  const [openingChatPage, setOpeningChatPage] = useState(false);
  const [isDeleted, setIsDeleted] = useState(null);

  item = JSON.parse(JSON.stringify(item));
  item = { ...item };

  if (isDeleted || item.isArchived) return <Container>Item Deleted</Container>;

  let chatInputText = "Join Chat";
  if (item.chatGroupMembership) chatInputText = "Open Chat";

  chatInputText = "Chat";

  let desc = "";

  if (item.description) {
    if (item.description.text) desc = item.description.text;
  }

  if (item.isBanned && !ignoreBan)
    return <Container>Item has been banned</Container>;

  let conclusionText = "Conclusions";

  if (item.conclusionCount > 1)
    conclusionText = `${item.conclusionCount} Conclusions`;

  let core = (
    <>
      <Main>
        <PrimaryLine>{capitalizeFirstLetter(item.title)}</PrimaryLine>

        {desc ? <Description>{desc}</Description> : null}

        <SecondaryLine>
          <Info>{item.author.username}</Info>
          <Info>Rooms: {item.roomsCreated}</Info>
          <Info>Room Capacity: {item.maxUsersPerRoom}</Info>
          {item.type == "PRIVATE" ? <Info> Private </Info> : null}
        </SecondaryLine>
      </Main>

      <Buttons>
        <ProfilePicture
          style={{
            border: "3px solid var(--primaryInput)",
          }}
          borderRadius="10px"
          data={item.author.profileImage}
          size={35}
          // mobileStyle={{ width: "20px" }}
          onClick={goTo(`/${item.author.username}`)}
          icon={<BiUser />}
        />

        <FilledButton
          style={{
            background: "var(--primaryInput)",
            padding: "0 25px",
            height: "35px",
          }}
          isLoading={openingChatPage}
          onClick={gotoChatPage}
        >
          {chatInputText}
        </FilledButton>

        {!forContentPage && (
          <FilledButton
            onClick={goTo(`/p/${item.slugID}/?popup=content`)}
            style={{
              padding: "0 25px",
              height: "35px",
            }}
          >
            {conclusionText}
          </FilledButton>
        )}

        {/* <LikeDislikeButtons
          likeStatus={item.likeStatus}
          likeCount={item.likeCount}
          dislikeCount={item.dislikeCount}
          contentID={item._id}
          authorUserID={item.authorUserID}
          colorVariation={"ACCENT"}
        /> */}

        {/* <FilledButton
          mobileStyle={{ width: "40px" }}
          style={{ width: "40px" }}
          onClick={goTo(`/${item.author.username}`)}
          icon={<BiUser />}
        /> */}

        <FilledButton
          style={{ height: "35px", width: "20px" }}
          onClick={showContentMenu}
          icon={<BsThreeDotsVertical />}
        />
      </Buttons>
    </>
  );

  if (forContentPage) {
    return <ForContentPage>{core}</ForContentPage>;
  }

  return (
    <Container>
      {core}

      {showReportsData ? (
        <ReportsDataBox item={item} subjectType={"CONTENT"} />
      ) : null}
    </Container>
  );

  function showContentMenu() {
    let options = [{ label: "Copy Link", onClick: copyLinkToClipboard }];

    if (loggedInUserID == item.authorUserID) {
      options = [
        ...options,
        { label: "Edit", onClick: goTo(`/edit-content/${item._id}`) },
        { label: "Delete", onClick: deleteContent },
      ];
    } else {
      options = [
        ...options,
        {
          label: "Report",
          onClick: goTo(
            `/create-report/?subjectType=content&subjectID=${item._id}`
          ),
        },
      ];
    }

    showPopup({ title: "options", component: <Options options={options} /> });
  }

  async function copyLinkToClipboard() {
    window.popupAlert("Link copied to clipboard");
    await window.navigator.clipboard.writeText(
      "https://cottonchat.com/p/" + item.slugID
    );
  }

  function deleteContent() {
    let options = [
      { label: "Yes! Delete Post", onClick: doIt },
      {
        label: "No",
        onClick: () => {
          goTo(-1)();
        },
      },
    ];

    showPopup({ title: "options", component: <Options options={options} /> });

    function doIt() {
      popupAlert("Post Deleted!");
      setIsDeleted(true);
      goTo(-1)();
      serverLine.delete(`/content/?contentID=${item._id}`);
    }
  }

  async function gotoChatPage() {
    if (!loggedInUserID) return window.popupAlert("Please! Login to continue");
    try {
      if (item.chatGroupMembership) {
        return goTo(
          `/chat/${item.chatGroupMembership.chatGroupID}/?popup=chat`
        )();
      }

      setOpeningChatPage(true);
      await openChat({ chatGroupType: "CONTENT_CHAT", contentID: item._id });
      setOpeningChatPage(false);
    } catch (e) {
      console.warn(e);
      window.popupAlert(e.message);
      setOpeningChatPage(false);
    }
  }
}

function parseCategory(cate) {
  return capitalizeFirstLetter(cate.toLowerCase().replace("_", " "));
}

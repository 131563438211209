import styled, { keyframes } from "styled-components";

const shimmerAnimation = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

// Create the styled component with shimmer effect
const ShimmerDarkMode = styled.div`
  width: 300px;
  height: 200px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  opacity: 0.5;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgb(0, 0, 0) 0%,
      rgb(31, 31, 31) 50%,
      rgb(0, 0, 0) 100%
    );
    background-size: 200% 100%;
    animation: ${shimmerAnimation} 1.5s infinite linear;
  }
`;

export default ShimmerDarkMode;

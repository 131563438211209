import { Preferences } from "@capacitor/preferences";
class UniversalLocalStorage {
  async getItem(key) {
    let data = await Preferences.get({ key });
    if (!data) return null;
    if (!data.value) return null;
    return data.value;
  }

  async setItem(key, value) {
    if (!value) return await Preferences.remove({ key });

    // console.log('saving', key, value);
    let data = await Preferences.set({
      key: key,
      value: value,
    });
    // console.log('saving data', key, data);
    return true;
  }

  async clear() {
    return await Preferences.clear();
  }

  async removeItem(key) {
    return await Preferences.remove({ key });
  }
}

const universalLocalStorage = new UniversalLocalStorage();

export default universalLocalStorage;

import { styled } from "styled-components";

import { MdOutlineAddReaction } from "react-icons/md";
import { GoReply } from "react-icons/go";
import ReplyOfBox from "./ReplyOfBox";
import AddMessageReaction from "../AddMessageReaction";
import MessageReactionsBox from "./MessageReactionsBox";
import useZekToolkit from "../../../../../zekFrontendToolkit/useZekToolkit";
import getImageURL from "../../../../../zekFrontendToolkit/mediaUpload/controller/getImageURL";
import ProfilePicture from "../../../../../util/components/ProfilePicture";
import { useContext } from "react";
import Context from "../../../../../Context";

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
  position: relative;
  opacity: 0;
  animation: fadeIn 0.3s ease-out forwards;
  animation-delay: 0.2s;

  &:hover .btns {
    opacity: 1;
  }
`;

const TheMessage = styled.div`
  border-radius: 0 13px 13px 13px;
  padding: 17px 15px;
  padding-right: 20px;
  position: relative;
  max-width: 62%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* white-space: pre; */
  margin: 0;
  background-color: var(--secondaryInput);

  @media (max-width: 900px) {
    max-width: 80%;
    padding: 10px 13px;
  }
`;

const Image = styled.img`
  height: 30px;
  border-radius: 500px;
  width: 30px;
  object-fit: cover;
`;

const AuthorLine = styled.div`
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 700;

  @media (max-width: 900px) {
    font-size: 11px;
  }
`;

const ContentLine = styled.div`
  font-size: 13px;
  font-weight: 600;

  @media (max-width: 900px) {
    font-size: 13px;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  opacity: 0;
  align-items: center;
`;

const MessageButton = styled.div`
  font-size: 15px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.5;
  background-color: var(--secondaryInput);
  border-radius: 10px;

  &:hover {
    opacity: 1;
  }
`;

export default function TheirMessage({ message, content }) {
  const { globalChatSpace } = useContext(Context);
  const { showPopup } = useZekToolkit();
  let { setReplyingTo } = globalChatSpace;

  return (
    <Container>
      <ProfilePicture size={35} data={message.messageAuthor.profileImage} />
      <TheMessage>
        <ReplyOfBox item={message.replyOf} />
        <AuthorLine>{message.messageAuthor.username}</AuthorLine>
        <ContentLine>{content}</ContentLine>
        <MessageReactionsBox alignment="right" item={message} />
      </TheMessage>
      <Buttons className="btns">
        <MessageButton onClick={setReply}>
          <GoReply />
        </MessageButton>
        <MessageButton onClick={addReaction}>
          <MdOutlineAddReaction />
        </MessageButton>
      </Buttons>
    </Container>
  );

  function addReaction() {
    showPopup({
      title: "Add Reaction",
      component: <AddMessageReaction item={message} />,
    });
  }

  function setReply() {
    setReplyingTo(message);
  }
}

import { styled } from "styled-components";

import { useEffect, useState } from "react";
import { MdOutlineEdit } from "react-icons/md";
import BeautifulTitle from "../../util/components/BeautifulTitle";
import LoadingSection from "../../util/components/LoadingSection";
import FilledButton from "../../util/components/buttons/FilledButton";

import serverLine from "../../zekFrontendToolkit/network/serverLine";
import ConclusionEditor from "../chatPage/components/conclusionSection/ConclusionEditor";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 50px; */
  /* overflow-y: scroll; */
  width: 100%;
  height: 100%;
`;

const Main = styled.div`
  width: 100%;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
    padding-top: 20px;
  }
`;

const WriteTitle = styled.textarea`
  height: 130px;
  resize: none;
  width: 38vw;
  border: none;
  color: var(--color);
  padding: 25px;
  background-color: var(--primarySurface);
  border-radius: 10px;
  font-size: 18px;
  font-weight: 700;
  /* text-align: center; */

  @media (max-width: 900px) {
    font-size: 13px;
  }
`;

const EditSection = styled.div`
  width: 38vw;
  padding: 25px;
  background-color: var(--primarySurface);
  border-radius: 15px;

  @media (max-width: 900px) {
    width: 90vw;
  }
`;

export default function EditConclusionPage({ pathSegments }) {
  const [loading, setLoading] = useState(true);
  const [postData, setPostData] = useState(null);

  let conclusionID = pathSegments[0];

  useEffect(() => {
    serverLine
      .get("/conclusion/?conclusionID=" + conclusionID)
      .then((newData) => {
        setPostData(newData);
        setLoading(false);
      });
  }, [conclusionID]);

  if (!postData || loading) return <LoadingSection />;

  return (
    <Container>
      <Main>
        <BeautifulTitle icon={<MdOutlineEdit />}>
          Update Conclusion
        </BeautifulTitle>
        <EditSection>
          <ConclusionEditor
            itemID={conclusionID}
            initialText={postData.data.text}
            initialImage={postData.data.images ? postData.data.images[0] : null}
          />
        </EditSection>
        <br /> <br /> <br /> <br /> <br /> <br />
      </Main>
    </Container>
  );
}

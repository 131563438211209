import { styled } from "styled-components";
import { useContext, useEffect } from "react";
import MessageBox from "./messageBox/MessageBox";
import LoadingSection from "../../../../util/components/LoadingSection";
import reachChatPanelEnd from "../../../../util/controllers/reachChatPanelEnd";
import Context from "../../../../Context";
import { AnimatePresence, motion } from "framer-motion";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  /* height: calc(100% - 300px); */
  overflow: hidden;
`;

const Messages = styled.div`
  display: flex;
  flex: 1;
  /* height: calc(100% - 300px); */
  /* height: calc(100% - 195px); // 200 subtracted for bottom bar */
  width: 100%;
  overflow-y: scroll;
  flex-direction: column;
  gap: 25px;
  padding: 20px 20px;
  /* padding-bottom: 80px; */

  @media (max-width: 900px) {
    gap: 15px;
  }
`;

const Notice = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  font-size: 27px;
  opacity: 0.7;
  font-size: 900;
  justify-content: center;
  align-items: center;
`;

export default function ChatMessagesPanel() {
  const { globalChatSpace } = useContext(Context);
  const { selectedChatGroupIsLoading, selectedChatGroupData } = globalChatSpace;

  useEffect(() => {
    // console.log("Scrolling to the end");

    setTimeout(() => {
      reachChatPanelEnd();
    }, 300); //Ensuring rending has been completed
  }, []); //ChatGroupBox was removed because it was scroll to the bottom on reactions as well

  if (selectedChatGroupIsLoading)
    return (
      <Container>
        <LoadingSection />
      </Container>
    );

  if (!selectedChatGroupData)
    return (
      <Container>
        <Notice> Please select a chat to start messaging </Notice>
      </Container>
    );

  let group = selectedChatGroupData.group;

  let messages = selectedChatGroupData.messages;

  // return (
  //   <Messages id="chat-panel">
  //     {messages.map((item) => (
  //       <MessageBox key={item._id} group={group} message={item} />
  //     ))}
  //   </Messages>
  // );

  return (
    <Messages id="chat-panel">
      <AnimatePresence>
        {messages.map((item, index) => (
          <motion.div
            layout
            key={item._id}
            layoutId={String(item._id)}
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.6, delay: 0.1 }}
            className="p-2 bg-gray-200 rounded"
          >
            <MessageBox key={item._id} group={group} message={item} />
          </motion.div>
        ))}
      </AnimatePresence>
    </Messages>
  );
}

import { useContext, useState } from "react";
import styled from "styled-components";
import Context from "../../../Context";
import limitStringLength from "../../controllers/limitStringLength";

import CustomToggle from "../inputs/CustomToggle";
import FilledButton from "../buttons/FilledButton";

import useZekToolkit from "../../../zekFrontendToolkit/useZekToolkit";
import serverLine from "../../../zekFrontendToolkit/network/serverLine";
import universalLocalStorage from "../../../zekFrontendToolkit/universalLocalStorage";
import initializeChat from "../../../pages/chatPage/controllers/socket/initializeChat";

const Container = styled.div`
  padding: 10px;
  background: var(--secondaryInput);
`;

function AccountSwitcher({ style = {}, colorVariation }) {
  let { allProfiles } = useContext(Context);
  let { loggedInUser, refreshUser, loggedInUserID } = useZekToolkit();
  let [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  let options = allProfiles.map((item) => {
    return {
      value: item._id,
      label: limitStringLength(item.name.split(" ")[0], 7),
    };
  });

  if (loading)
    return (
      <FilledButton
        colorVariation={colorVariation}
        style={{ width: "80%", ...style }}
      >
        Please Wait...
      </FilledButton>
    );

  return (
    <CustomToggle
      btnStyle={{ width: "100%", borderRadius: "10px", flex: 1 }}
      value={loggedInUserID}
      colorVariation={colorVariation}
      options={options}
      onChange={changeProfile}
      toggleContainerStyle={{ width: "100%" }}
      toggleStyle={{
        width: "80%",
        padding: "0",
        display: "grid",

        gridTemplateColumns: "1fr 1fr",

        ...style,
      }}
    />
  );

  async function done(data) {
    if (data) {
      if (window.chatSocket) {
        window.chatSocket.disconnect();
      }

      await universalLocalStorage.setItem("cottonchat-token", data.token);
      await universalLocalStorage.setItem("cottonchat-userID", data.userID);
      await refreshUser();
      setLoading(false);

      for (let item of allProfiles) {
        if (data.userID == item._id) {
          window.popupAlert(`Welcome ${item.username}`);
        }
      }
    }
  }

  function changeProfile(newID) {
    setLoading(true);
    serverLine
      .post("/switch-profile", { id: newID })
      .then(done)
      .catch(({ message }) => {
        console.warn(message);
        if (typeof message == "object") message = JSON.stringify(message);
        window.popupAlert(message);
        setError(message);
      });
  }
}

export default AccountSwitcher;

import { useContext } from "react";
import Context from "../../../Context";
import styled from "styled-components";
import { RiChat3Line, RiHomeLine, RiNotificationLine } from "react-icons/ri";
import calculateUnreadMessagesCount from "../../../pages/chatPage/controllers/ui/calculateUnreadMessagesCount";

import useZekToolkit from "../../../zekFrontendToolkit/useZekToolkit";
import { BiUser } from "react-icons/bi";
import { LuFeather } from "react-icons/lu";
import NavWithAnimatedHighlight from "../navWithAnimatedHighlight/NavWithAnimatedHighlight";

const MobileBottomNav = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;

  align-items: center;
  border-radius: 0;
  width: 100vw;
  font-family: "Raleway";
  background: var(--primarySurface);

  height: 60px;
  z-index: 66;
  border-radius: 15px 15px 0 0;
  animation: fadeIn 0.2s ease-out forwards;

  box-shadow: 0 -10px 20px 0 rgb(2 3 60 / 12%);
  /* border-bottom: 1px solid var(--mobileHeaderBorder); */

  @media (max-height: 600px) {
    //Iphone se, which is the smallest iPhone, has the height of 650px
    display: none;
  }
`;

const Items = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  padding-bottom: 30px;
  align-items: center;

  flex-direction: column;

  @media (max-width: 900px) {
    flex-direction: row;
    padding: 0 10px;
    gap: 0;
    width: auto;
    justify-content: space-between;
    /* width: 100%; */
  }
`;

// const NotificationBubbleAlert = styled(NotificationBubble)`
//   /* color: tomato; */
//   background-color: tomato;
// `;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  padding: 35px 0;
  align-items: center;

  width: 100%;

  background: var(--primarySurface);
  border-bottom: 1px solid var(--foreground);

  @media (max-width: 900px) {
    background: transparent;
    border: none;
    width: auto;
  }
`;

const NavIconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  animation: centerScaleReveal 0.5s ease-out forwards;
  padding: 20px 20px;
  position: relative;
  border-radius: 10px;
  /* background-color: var(--secondaryInput); */

  /* &:hover {
    background-color: var(--primaryInput);
  } */

  &:after {
    content: "";
    background: var(--primaryInput);
    display: block;
    position: absolute;
    width: 0%;
    height: 0%;
    left: 50%;
    top: 50%;
    opacity: 0;
    border-radius: 100px;
    transition: all ease-out 0.8ms;
  }

  &:active:after {
    padding: 0;
    margin: 0;
    opacity: 0.3;
    transition: 0s;
    width: 90%;
    height: 90%;
    left: 5%;
    top: 5%;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0;
`;

const MobileLogo = styled.div`
  width: 80px;
  height: 30px;
  background: var(--textLogo);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

export default function MobileFloatingHeader() {
  const { notificationData } = useContext(Context);
  const { loggedInUserID, loggedInUser } = useZekToolkit();

  if (!loggedInUserID) return null;

  let unreadMessages = calculateUnreadMessagesCount();

  let options = [
    { name: "home", path: "/", icon: <RiHomeLine /> },
    {
      name: "chat",
      path: "/chat",
      icon: <RiChat3Line />,
      bubble: unreadMessages,
    },
    { name: "createContent", path: "/create", icon: <LuFeather /> },
    {
      name: "notifications",
      path: "/notifications",
      icon: <RiNotificationLine />,
      bubble: notificationData.general,
    },
    { name: "profile", path: `/${loggedInUser.me.username}`, icon: <BiUser /> },
  ];

  return (
    <MobileBottomNav>
      <NavWithAnimatedHighlight options={options} widthInPercentage={97} />
    </MobileBottomNav>
  );
}

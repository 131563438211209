import useZekToolkit from "../../../zekFrontendToolkit/useZekToolkit";

import ShimmerLightMode from "./ShimmerLightMode";
import ShimmerDarkMode from "./ShimmerDarkMode";

export default function NotificationPageShimmer() {
  const { isMobile, colorMode } = useZekToolkit();

  let totalItems = 20;

  let items = [];

  let Comp = ShimmerLightMode;

  if (colorMode == "DARK") Comp = ShimmerDarkMode;

  for (let i = 0; i < totalItems; i++) {
    items.push(
      <Comp
        style={{
          borderRadius: "20px",
          width: "100%",
          height: "80px",
        }}
        // height={300}
        // width={isMobile ? mobileWidth : desktopWidth}
      />
    );
  }

  return items;
}

import { styled } from "styled-components";
import MessageReactionsAuthorList from "./MessageReactionsAuthorList";
import useZekToolkit from "../../../../../zekFrontendToolkit/useZekToolkit";

const Container = styled.div`
  padding: 100%;
  background-color: var(--primaryInput);
  padding: 2px 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  position: absolute;
  font-size: 17px;
  bottom: -13px;
  cursor: pointer;

  &:hover {
    /* background-color: var(--primaryInput); */
  }

  @media (max-width: 900px) {
    bottom: -10px;
    font-size: 13px;
  }

  ${({ alignment }) => {
    if (alignment === "right") {
      return `right: 5px`;
    } else {
      return `left: 5px`;
    }
  }}
`;

const Emoji = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;

  animation: centerScaleReveal 0.3s ease-out forwards;
`;

const Value = styled.div``;

const Count = styled.div``;

export default function MessageReactionsBox({ item, alignment }) {
  const { showPopup } = useZekToolkit();

  if (!item) return null;

  if (!item.reactions) return null;

  let reactionVsCount = {};

  for (let userID in item.reactions) {
    let val = item.reactions[userID];
    let emoji = val.value;
    if (!emoji) continue;
    if (!reactionVsCount[emoji]) reactionVsCount[emoji] = 0;
    reactionVsCount[emoji]++;
  }

  let list = [];

  for (let emoji in reactionVsCount) {
    let count = reactionVsCount[emoji];

    list.push(
      <Emoji key={emoji}>
        <Value>{emoji}</Value>
        {count > 1 ? <Count>{count}</Count> : null}
      </Emoji>
    );
  }

  if (!list.length) return null;

  return (
    <Container alignment={alignment} onClick={showAuthorData}>
      {list}
    </Container>
  );

  function showAuthorData() {
    showPopup({
      title: "Reactions",
      component: <MessageReactionsAuthorList item={item} />,
    });
  }
}

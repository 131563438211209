import { styled } from "styled-components";

import { useEffect, useRef } from "react";

import { FiArrowLeft } from "react-icons/fi";
import { CgClose } from "react-icons/cg";

import getUrlQuery from "./controllers/getUrlQuery";
import useZekToolkit from "../useZekToolkit";
import ZekButton from "../inputUI/ZekButton";

const Container = styled.div`
  position: fixed;
  height: 100vh;
  height: 100svh;
  width: 100vw;
  z-index: 200;
  top: 0;
  left: 0;
`;

const Background = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  /* background: var(--translucentHard); */
  /* backdrop-filter: blur(50px); */
  animation: fadeIn 0.25s ease-out forwards;
  background: var(--popupBackground);

  /* background-color: rgba(20, 20, 20, 0.1); */
`;

const Main = styled.div`
  position: absolute;
  width: 80vw;
  left: 10vw;
  top: 0;
  height: 100vh;
  height: 100svh;
  display: flex;
  padding-top: 15px;
  flex-direction: column;

  @media (max-width: 900px) {
    width: 100vw;
    flex-direction: column;
    padding-top: 0;
    left: 0vw;
  }
`;

const MainCore = styled.div`
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  overflow-y: auto;
  height: calc(100vh - 110px);
  height: calc(100svh - 110px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5px;

  /* background: var(--secondarySurface); */
  /* border: 1px solid var(--primarySurface); */

  /* box-shadow: 1px 3px 10px 0 rgba(0, 0, 0, 0.1); */

  @media (max-width: 900px) {
    height: calc(100vh);
    height: calc(100svh);
    padding: 0px 0;
  }
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  padding: 20px 0;
  /* position: fixed; */
  /* left: 10vw; */
  /* top: 20px; */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 900px) {
    display: none;
  }
`;

const LeftButton = styled.div`
  position: fixed;
  left: 50px;
  top: 50px;

  @media (max-width: 900px) {
    display: non;
  }
`;

const RightButton = styled.div`
  position: fixed;
  right: 50px;
  top: 50px;
  @media (max-width: 900px) {
    display: none;
  }
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: var(--borderRadius);
  background-color: var(--secondarySurface);
  gap: 10px;
  font-size: 15px;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  height: 30px;
  width: 50px;

  &:hover {
    transform: scale(0.95);
    background: var(--primarySurface);
  }
`;

const Title = styled.div`
  text-transform: capitalize;
`;

export default function PopupRoute({ element }) {
  const { nonPopupRoute, goTo, currentRoute } = useZekToolkit();

  let popupTitle = getUrlQuery().popup;
  const ref = useRef(null);

  useEffect(() => {
    if (ref) {
      if (ref.current) {
        ref.current.scrollTop = 0; // For Safari
        // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }
    }
  }, [currentRoute]);

  let buttons = (
    <Buttons>
      <LeftButton>
        <ZekButton
          colorVariation={"SURFACE"}
          style={{
            height: "50px",
            border: "none",
            background: "var(--translucent)",
          }}
          icon={<FiArrowLeft />}
          onClick={goBack}
        >
          {/* {popupTitle ? popupTitle : "Back"} */}
        </ZekButton>
      </LeftButton>

      <RightButton>
        <ZekButton
          colorVariation={"SURFACE"}
          style={{
            height: "50px",
            width: "50px",
            border: "none",
            background: "var(--translucent)",
          }}
          onClick={closePopup}
          icon={<CgClose />}
        ></ZekButton>
      </RightButton>
    </Buttons>
  );

  return (
    <Container>
      <Background onClick={goBack} />
      <Main>
        {buttons}
        <MainCore ref={ref}>{element}</MainCore>
      </Main>
    </Container>
  );

  function goBack() {
    goTo(-1)();
  }

  function closePopup() {
    goTo(nonPopupRoute)();
  }
}

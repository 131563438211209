import loadSelectedChatGroup from "../../controllers/api/loadSelectedChatGroup";

import leaveChatGroup from "../../controllers/api/leaveChatGroup";
import BlockUnblockInterface from "../../../profilePage/BlockUnblockInterface";

import Options from "../../../../util/components/inputs/Options";
import muteSelectedChatGroup from "../../controllers/api/muteSelectedChatGroup";
import getChatGroupMuteStatus from "../../controllers/ui/getChatGroupMuteStatus";
import useZekToolkit from "../../../../zekFrontendToolkit/useZekToolkit";
import Context from "../../../../Context";
import { useContext } from "react";

export default function ChatOptionsSection() {
  const { globalChatSpace } = useContext(Context);
  const { loggedInUserID, showPopup } = useZekToolkit();

  const { selectedChatGroupID, selectedChatGroupData } = globalChatSpace;

  let oppositeMember = selectedChatGroupData.oppositeMember;

  let options = [];

  let muteStatus = getChatGroupMuteStatus();

  options = [
    {
      label: muteStatus == "MUTED" ? "Unmute" : "Mute",
      onClick: () => {
        muteSelectedChatGroup();
      },
    },
  ];

  if (selectedChatGroupData.group.type == "DIRECT_CHAT") {
    options.push({
      label: "Manage Blocking",
      onClick: manageBlocking,
    });
  } else {
    options.push({
      label: "Leave Room",
      onClick: leaveRoom,
    });
  }

  return <Options options={options} />;

  function leaveRoom() {
    leaveChatGroup(selectedChatGroupData.group._id);
  }

  function manageBlocking() {
    let receiverUserData = oppositeMember;

    showPopup({
      title: "Manage Blocking",
      component: (
        <BlockUnblockInterface
          receiverUserData={receiverUserData}
          receiverUserID={receiverUserData._id}
          senderUserID={loggedInUserID}
          callback={() => {
            loadSelectedChatGroup(selectedChatGroupID);
          }}
        />
      ),
    });
  }
}

import styled from "styled-components";
import FilledButton from "../../../../util/components/buttons/FilledButton";
import { useContext, useState } from "react";
import sendVotedOut from "../../controllers/socket/send/sendKickOut";
import getObjectID from "../../../../util/controllers/getObjectID";
import sendMessage from "../../controllers/socket/send/sendMessage";
import useZekToolkit from "../../../../zekFrontendToolkit/useZekToolkit";
import serverLine from "../../../../zekFrontendToolkit/network/serverLine";
import getImageURL from "../../../../zekFrontendToolkit/mediaUpload/controller/getImageURL";
import ProfilePicture from "../../../../util/components/ProfilePicture";
import Context from "../../../../Context";

const Container = styled.div`
  background: var(--secondaryInput);
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  animation: centerScaleReveal 0.3s ease-out forwards;
`;
const Main = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
`;

const MainInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Name = styled.div`
  font-size: 18px;
  font-weight: 900;
`;
const SecondaryInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-size: 15px;
`;

const InfoText = styled.div``;

export default function ChatMemberBox({ userData, voteData, membershipData }) {
  const { loggedInUserID } = useZekToolkit();
  const { globalChatSpace } = useContext(Context);

  const [voteOutStatus, setVoteOutStatus] = useState(voteData);
  const [removed, setRemoved] = useState(false);
  const [increment, setIncrement] = useState(0);

  if (removed) return null;
  return (
    <Container>
      <Main>
        <ProfilePicture size={"60px"} data={userData.profileImage} />

        <MainInfo>
          <Name>{userData.name}</Name>
          <SecondaryInfo>
            <InfoText>{userData.username}</InfoText>
            <InfoText>.</InfoText>
            <InfoText>
              Vote Outs: {membershipData.voteOuts + increment}
            </InfoText>
          </SecondaryInfo>
        </MainInfo>
      </Main>

      <Buttons>
        <FilledButton href={"/" + userData.username} colorVariation={"SURFACE"}>
          Profile
        </FilledButton>
        {getButton()}
      </Buttons>
    </Container>
  );

  function getButton() {
    if (userData._id == loggedInUserID) return null;
    if (voteOutStatus)
      return (
        <FilledButton colorVariation={"SURFACE"} onClick={voteOut}>
          Cancel Vote Out
        </FilledButton>
      );

    return (
      <FilledButton colorVariation={"SURFACE"} onClick={voteOut}>
        Vote Out
      </FilledButton>
    );
  }

  function sendYellowCardMessage() {
    let { selectedChatGroupData } = globalChatSpace;

    let messageID = getObjectID();

    let messageDoc = {
      _id: messageID,
      type: "VOTE_OUT",
      subjectMemberID: userData._id,
      subjectMember: userData,
    };

    sendMessage({
      messageDoc,
      chatGroup: selectedChatGroupData.group,
    });
  }

  function sendYellowCardRevokedMessage() {
    let { selectedChatGroupData } = globalChatSpace;

    let messageID = getObjectID();

    let messageDoc = {
      _id: messageID,
      type: "VOTE_OUT_REVOKED",
      subjectMemberID: userData._id,
      subjectMember: userData,
    };

    sendMessage({
      messageDoc,
      chatGroup: selectedChatGroupData.group,
    });
  }

  function sendKickOutMessage() {
    let { selectedChatGroupData } = globalChatSpace;

    let messageID = getObjectID();

    let messageDoc = {
      _id: messageID,
      type: "KICK_OUT",
      subjectMemberID: userData._id,
      subjectMember: userData,
    };

    sendMessage({
      messageDoc,
      chatGroup: selectedChatGroupData.group,
    });
  }

  async function voteOut() {
    let { loggedInUserID } = globalChatSpace;

    if (userData._id == loggedInUserID)
      return window.popupAlert("You can't vote out yourself");
    if (voteOutStatus) {
      sendYellowCardRevokedMessage();
      setIncrement(increment - 1);
    } else {
      sendYellowCardMessage();
      setIncrement(increment + 1);
    }

    let { selectedChatGroupID } = globalChatSpace;
    setVoteOutStatus(voteOutStatus ? false : true);
    let data = await serverLine.post("/vote-out-chat-member", {
      receiverUserID: userData._id,
      chatGroupID: selectedChatGroupID,
    });

    if (data) {
      window.popupAlert(
        "The member has been removed & banned from all rooms of this post."
      );
      sendKickOutMessage();
      sendVotedOut({ userID: userData._id, chatGroupID: selectedChatGroupID });
      setRemoved(true);
    }
  }
}

import { styled } from "styled-components";
import { AiOutlineSend } from "react-icons/ai";
import { useContext, useEffect, useState } from "react";

import LoadingSection from "../../../../util/components/LoadingSection";

import MaterialInput from "../../../../util/components/MaterialInput";

import FilledButton from "../../../../util/components/buttons/FilledButton";

import FeedItemConclusion from "../../../../util/components/feedItem/FeedItemConclusion";
import serverLine from "../../../../zekFrontendToolkit/network/serverLine";
import Context from "../../../../Context";
import { heIL } from "@mui/x-date-pickers";
import { BiImage } from "react-icons/bi";
import compressAndUploadFile from "../../../../zekFrontendToolkit/mediaUpload/controller/compressAndUploadFile";
import selectFile from "../../../../zekFrontendToolkit/mediaUpload/controller/selectFile";
import ConclusionEditor from "./ConclusionEditor";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Cards = styled.div`
  display: flex;
  /* flex: 1; */
  width: 100%;
  flex-direction: column;
  gap: 25px;
  padding: 20px 20px;
`;
const EditingPart = styled.div`
  /* height: 100px; */
  width: 100%;
  padding: 10px;
  align-items: flex-end;
  gap: 10px;
  padding: 15px 15px;
  display: flex;
  flex-direction: column;

  border-bottom: 1px solid var(--translucentHard);

  @media (max-width: 900px) {
    padding: 20px 20px;
    border: none;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

export default function ConclusionSection() {
  const { globalChatSpace } = useContext(Context);
  const { selectedChatGroupID, selectedChatGroupData } = globalChatSpace;
  const [text, setText] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const [conclusions, setConclusions] = useState([]);

  useEffect(() => {
    loadConclusions();
  }, [selectedChatGroupID]);

  if (!selectedChatGroupData) return;
  if (!selectedChatGroupData.group) return;

  let bottomBtnStyle = { height: "56px", width: "56px" };

  let editingPart = (
    <>
      <MaterialInput
        label={"Type your conclusion Here"}
        onChange={updateText}
        value={text}
        rows={6}
        maxRows={12}
        multiline={true}
      />

      <Buttons>
        <FilledButton
          style={{ height: "50px" }}
          variant="filled"
          icon={<BiImage />}
          onClick={doImageSelection}
        ></FilledButton>

        <FilledButton
          style={{ height: "50px" }}
          variant="filled"
          onClick={postConclusions}
          icon={<AiOutlineSend />}
        >
          Publish Conclusion
        </FilledButton>
      </Buttons>
    </>
  );

  if (loading)
    return (
      <Container>
        <LoadingSection />
      </Container>
    );

  return (
    <Container>
      <EditingPart>
        <ConclusionEditor onDone={loadConclusions} />
      </EditingPart>
      <Cards>
        {conclusions.map((item) => (
          <FeedItemConclusion key={item._id} item={item} />
        ))}
      </Cards>
    </Container>
  );

  async function doImageSelection() {
    let images = selectFile();

    if (images) {
      if (images[0]) {
        setSelectedImage(images[0]);
      }
    }
  }

  async function loadConclusions() {
    setLoading(true);
    let data = await serverLine.get(
      `/conclusions/?contentID=${selectedChatGroupData.group.contentID}`
    );
    setConclusions(data);
    setLoading(false);
  }

  function updateText(e) {
    setText(e.target.value);
  }

  async function postConclusions() {
    if (!text) return window.popupAlert("text can't be empty");
    if (!text.trim()) return window.popupAlert("text can't be empty");

    try {
      setLoading(true);

      let theImage = null;

      if (selectedImage)
        theImage = await compressAndUploadFile({
          selectedFile: selectedImage,
          folder: "conclusion",
        });

      await serverLine.post("/conclusion", {
        chatGroupID: selectedChatGroupID,
        data: { text, images: [theImage] },
      });

      await loadConclusions();
      setText("");
      setLoading(false);
    } catch (e) {
      console.warn(e);
      setLoading(false);
      window.popupAlert(e.message);
    }
  }
}

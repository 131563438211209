import MobileChatMainPanel from "./MobileChatMainPanel";
import DesktopChatMainPanel from "./DesktopChatMainPanel";
import useZekToolkit from "../../../../zekFrontendToolkit/useZekToolkit";
import { useContext } from "react";
import Context from "../../../../Context";

export default function ChatMainPanel() {
  const { isMobile } = useZekToolkit();
  const { globalChatSpace } = useContext(Context);

  let { selectedChatGroupID } = globalChatSpace;

  if (!selectedChatGroupID) return null;

  if (isMobile) return <MobileChatMainPanel />;

  return <DesktopChatMainPanel />;
}

import { styled } from "styled-components";
import { useEffect, useState } from "react";
import LoadingSection from "../../util/components/LoadingSection";
import HrWithTitle from "../../util/components/HrWithTitle";
import FeedItemConclusion from "../../util/components/feedItem/FeedItemConclusion";
import serverLine from "../../zekFrontendToolkit/network/serverLine";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
`;

const Cards = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  overflow-y: scroll;
  flex-direction: column;
  gap: 25px;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 100%;
`;

const HeaderTitle = styled.div`
  font-size: 21px;
`;

const Hr = styled.div`
  height: 1px;
  width: 100%;
  opacity: 0.4;
  background: var(--accentColor);
`;

export default function ContentPageConclusions({ contentID }) {
  const [loading, setLoading] = useState(false);

  const [conclusions, setConclusions] = useState([]);

  useEffect(() => {
    loadConclusions();
  }, [contentID]);

  if (loading)
    return (
      <Container>
        <LoadingSection />
      </Container>
    );

  return (
    <Container>
      <HrWithTitle title={"Conclusions"} />
      <Cards>
        {conclusions.map((item) => (
          <FeedItemConclusion item={item} colorVariation={"ACCENT"} />
        ))}
      </Cards>
    </Container>
  );

  async function loadConclusions() {
    setLoading(true);
    let data = await serverLine.get(`/conclusions/?contentID=${contentID}`);
    setConclusions(data);
    setLoading(false);
  }
}

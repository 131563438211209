export default function getRandomLivingThingEmoji() {
  const fruitAndVegetableEmojis = [
    "🍏",
    "🍎",
    "🍐",
    "🍊",
    "🍋",
    "🍌",
    "🍉",
    "🍇",
    "🍓",
    "🫐",
    "🍈",
    "🍒",
    "🍑",
    "🥭",
    "🍍",
    "🥥",
    "🥝",
    "🍅",
    "🥑",
    "🍆",
    "🥔",
    "🥕",
    "🌽",
    "🌶️",
    "🥒",
    "🥬",
    "🥦",
    "🧄",
    "🧅",
    "🍄",
  ];

  const emojis = [
    ...fruitAndVegetableEmojis,
    // Birds
    "🐦",
    "🦜",
    "🦢",
    "🦉",
    "🦩",
    "🐧",
    "🕊️",
    "🦆",
    "🦃",
    "🐔",
    "🐓",
    "🐤",
    "🐣",
    "🐥",
    // Animals
    "🐶",
    "🐱",
    "🐭",
    "🐰",
    "🐻",
    "🐼",
    "🐯",
    "🦁",
    "🐮",
    "🐷",
    "🐸",
    "🐵",
    "🐙",
    "🐠",
    "🦀",
    "🦖",
    "🐊",
    "🐢",
    "🦦",
    "🦔",
    "🦇",
    "🦘",
    "🦨",
    "🦡",
    "🦥",
    "🦒",
    "🦏",
    "🐘",
    "🐪",
    "🐫",
    "🦍",
    "🦓",
    "🦬",
    "🦄",
    // Flowers
    "🌸",
    "🌼",
    "🌻",
    "🌹",
    "🥀",
    "🌷",
    "💐",
    "🌺",
    "🌾",
    "🌿",
  ];

  return emojis[Math.floor(Math.random() * emojis.length)];
}

import styled from "styled-components";

import { useEffect, useState } from "react";

import CustomMasonry from "../../util/components/CustomMasonry.js";
import CustomToggle from "../../util/components/inputs/CustomToggle.js";
import FeedItemDiscussion from "../../util/components/feedItem/FeedItemDiscussion.js";
import FeedItemConclusion from "../../util/components/feedItem/FeedItemConclusion.js";
import LoadingSection from "../../util/components/LoadingSection.js";
import serverLine from "../../zekFrontendToolkit/network/serverLine.js";
import MasonryShimmer from "../../util/components/shimmer/MasonryShimmer.js";

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  width: var(--contentWidth);
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 25px;
  gap: 15px;
  border-radius: 25px;
  cursor: pointer;
  border: 1px solid var(--translucentHard);
  align-items: center;
`;

const NothingToShow = styled.div``;

const NoMasonry = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 25px;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const TopPart = styled.div`
  width: 45vw;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export default function ProfileFeed({ userData }) {
  const [type, setType] = useState("DISCUSSION");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    doLoading();
  }, [type]);

  let options = [
    { value: "DISCUSSION", label: "Discussions" },
    { value: "CONCLUSION", label: "Conclusions" },
  ];

  let topPart = (
    <TopPart>
      <CustomToggle
        colorVariation="SURFACE"
        options={options}
        value={type}
        onChange={(newType) => {
          setType(newType);
          setData([]);
        }}
      />
    </TopPart>
  );

  if (loading || !data)
    return (
      <Section>
        {topPart}
        <MasonryShimmer />
      </Section>
    );

  let colsOnMobile = 1;

  let content = [];

  if (data.length) {
    let list = data.map((item) => {
      if (type == "DISCUSSION")
        return <FeedItemDiscussion key={item._id} item={item} />;

      if (type == "CONCLUSION")
        return (
          <FeedItemConclusion
            showContentData={true}
            colorVariation={"SURFACE"}
            key={item._id}
            item={item}
          />
        );
    });

    content = (
      <CustomMasonry colsOnMobile={colsOnMobile} maxCol={2} list={list} />
    );
  } else {
    content = <NothingToShow> Nothing </NothingToShow>;
  }

  return (
    <Section>
      {topPart}
      {content}
    </Section>
  );

  async function doLoading() {
    setLoading(true);

    let data = await serverLine.get(
      `/feed/?type=${type.toLowerCase()}&userID=${userData._id}`
    );
    setData(data);

    setLoading(false);
  }
}

import { useContext } from "react";
import Context from "../../../../../Context";
import { styled } from "styled-components";

import TickStatus from "./TickStatus";
import ReplyOfBox from "./ReplyOfBox";
import MessageReactionsBox from "./MessageReactionsBox";

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 5px;
  /* opacity: 0; */

  /* animation-delay: 0.2s; */
`;

const TheMessage = styled.div`
  border-radius: 13px 0 13px 13px;
  padding: 15px 20px;
  position: relative;
  margin: 0;
  max-width: 62%;
  background-color: var(--secondaryInput);

  animation: fadeIn 0.6s ease-out forwards;

  @media (max-width: 900px) {
    max-width: 80%;
    padding: 10px 13px;
  }
`;

const MainContent = styled.div`
  font-size: 13px;
  font-weight: 600;
  font-family: "Montserrat";

  @media (max-width: 900px) {
    font-size: 13px;
  }
`;

export default function MyMessage({ message, group, oppositeMember, content }) {
  return (
    <Container>
      <TheMessage>
        <ReplyOfBox item={message.replyOf} />
        <MainContent>{content}</MainContent>
        <MessageReactionsBox alignment={"left"} item={message} />
      </TheMessage>
      <TickStatus message={message} />
    </Container>
  );
}

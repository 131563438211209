export default function getUrlSegments(path) {
  if (!path) path = window.location.href;

  path = path.split("?")[0];

  let split = path.split("/");
  let items = split.reverse();

  let toReturn = [];

  for (let item of items) {
    //removed empty segments that appears because of extra slash
    if (item.trim()) toReturn.push(item);
  }

  return toReturn;
}

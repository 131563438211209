import styled from "styled-components";
import { useEffect } from "react";
import BackButton from "./BackButton.jsx";
import getUrlQuery from "../router/controllers/getUrlQuery.js";
import urlQueryObjToUrl from "../router/controllers/urlQueryObjToUrl.js";
import { motion } from "framer-motion";

const FormHeader = styled.div`
  display: flex;
  flex-direction: row;
  opacity: 0.7;
  justify-content: space-between;
  padding: 20px;
  padding-bottom: 0;
`;

const Content = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  left: 0;
  top: 0;
  border-radius: 0 0 10px 10px;
  width: 100vw;
  position: fixed;
  z-index: 3500;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  background: var(--bgColor);
  box-shadow: 1px 1px 20px 20px #d1da3012;
  animation: centerScaleReveal 0.4s ease-in;
  box-shadow: var(--lightShadow);
  backdrop-filter: blur(100px);

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border: 1px solid black;
  }

  @media (max-width: 900px) {
    border: none;
  }

  @media (min-width: 900px) {
    left: 30.5vw;
    top: 25px;
    border-radius: 10px;
    width: 39vw;
    border: none;
    max-height: 90vh;
  }

  ${({ height }) => {
    if (height)
      return `
        height: ${height} !important;
      `;
  }}

  ${({ width }) => {
    if (width)
      return `
        width: ${width} !important;
        left: calc((100vw - ${width})/2) !important;
      `;
  }}
`;

const MainContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100vw;
  z-index: 1000;
  overflow: hidden;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : "rgba(0,0,0,0.6)"};
  z-index: 1500;
  height: 100dvh;
  backdrop-filter: blur(15px);
  width: 100vw;
  opacity: 1;
  display: block;
  animation: fadeIn 0.2s ease-in;
`;

function DynamicPopup({ setData, data, currentRoute }) {
  useEffect(() => {
    removeFormQuery();
    backFeatures();
  }, []);

  let urlQuery = getUrlQuery(currentRoute);
  // console.log('urlQuery', urlQuery);
  if (!urlQuery.formPage) return null;

  if (!data) return [];
  if (!data.settings) data.settings = {};
  let { height, width } = data.settings;

  if (!data.component) data.component = null;

  let header = null;
  if (data.title)
    header = (
      <FormHeader key="title">
        <BackButton
          onClick={() => {
            closeButton();
          }}
          title={data.title}
        />
      </FormHeader>
    );

  let mainContent = (
    <>
      {header}
      <Content>{data.component ? data.component : null}</Content>
    </>
  );

  return (
    <MainContainer>
      <motion.div
        style={{
          zIndex: 3500,
          position: "fixed",
          width: "100vw",
          top: 0,
        }}
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0, opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <Container width={width} height={height}>
          {mainContent}
        </Container>
      </motion.div>

      <Background onClick={closeButton} />
    </MainContainer>
  );

  function removeFormQuery() {
    let queryObj = getUrlQuery();

    if (queryObj.formPage) {
      let path = window.location.pathname;
      let lastLetterOfPath = path[path.length - 1];
      delete queryObj.formPage;
      let newPath = "/";
      let queryPath = urlQueryObjToUrl(queryObj);

      if (queryPath == "/?") queryPath = "";

      if (path === "/") {
        newPath = queryPath;
      } else {
        if (lastLetterOfPath == "/" && queryPath[0] == "/") {
          queryPath = queryPath.slice(1, queryPath.length);
        }
        newPath = path + queryPath;
      }

      let lastLetterOfNewPath = newPath[newPath.length - 1];
      if (lastLetterOfNewPath == "/") {
        newPath = newPath.slice(0, newPath.length - 1);
      }

      if (!newPath) newPath = "/";
      window.history.replaceState({}, "", newPath);
    }
  }

  function backFeatures() {
    window.addEventListener("popstate", () => {
      removeFormOnBack();
    });
  }

  function removeFormOnBack() {
    setData(null);
    removeFormQuery();
  }

  function closeButton() {
    window.history.back();
  }
}

export default DynamicPopup;

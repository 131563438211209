import { styled } from "styled-components";
import { useEffect, useState } from "react";
import RenderContent from "./RenderContent";
import LoadingSection from "../../util/components/LoadingSection";
import serverLine from "../../zekFrontendToolkit/network/serverLine";
import AnimatedPage from "../../zekFrontendToolkit/ui/animation/AnimatedPage";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 50px; */
  /* overflow-y: scroll; */
  width: 100%;
  height: 100%;
`;

const Main = styled.div`
  width: 62vw;
  background: var(--primarySurface);
  min-height: 80vh;
  border-radius: 10px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px;

  @media (max-width: 900px) {
    width: 100%;
    padding: 20px;
  }
`;

export default function ContentPage({ pathSegments }) {
  const [loading, setLoading] = useState(true);
  const [postData, setPostData] = useState(null);

  useEffect(() => {
    console.log(pathSegments);
    let slugID = pathSegments[0];
    serverLine.get("/content/?slugID=" + slugID).then((newData) => {
      setPostData(newData);
      setLoading(false);
    });
  }, [pathSegments]);

  if (!postData || loading) return <LoadingSection />;

  return (
    <AnimatedPage>
      <Container>
        <Main>
          <RenderContent setPostData={setPostData} postData={postData} />
          <br /> <br /> <br /> <br /> <br /> <br />
        </Main>
      </Container>{" "}
    </AnimatedPage>
  );
}

import styled, { keyframes } from "styled-components";

const shimmerAnimation = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

// Create the styled component with shimmer effect
const ShimmerLightMode = styled.div`
  width: 300px;
  height: 200px;
  background: #f6f7f8;
  opacity: 0.5;
  border-radius: 4px;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgb(214 223 245) 0%,
      rgb(234 240 255) 50%,
      rgb(213 222 245) 100%
    );
    background-size: 200% 100%;
    animation: ${shimmerAnimation} 1.5s infinite linear;
  }
`;

export default ShimmerLightMode;

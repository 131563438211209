import { styled } from "styled-components";
import { useState } from "react";
import ContentFeed from "./ContentFeed";
import CustomToggle from "../../../../util/components/inputs/CustomToggle";
import contentCategories from "../../../../data/contentCategories";
import DropDownInput from "../../../../util/components/inputs/DropDownInput";
import useZekToolkit from "../../../../zekFrontendToolkit/useZekToolkit";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  width: 62vw;

  padding: 0;
  padding-top: 20px;
  /* overflow: hidden; */ //It clips the shadow of the content
  /* overflow-y: scroll; */

  @media (max-width: 900px) {
    padding: 0;
    gap: 35px;
    width: calc(100vw - 40px);
    min-height: 70vh;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  /* width: 38vw; */

  @media (max-width: 900px) {
    flex-direction: row;
    gap: 15px;
    /* justify-content: space-between; */
    width: 100%;
    /* gap: 20px; */
    justify-content: flex-start;
  }
`;

let typeOptions = [
  { label: "Discussions", value: "DISCUSSION" },
  { label: "Conclusions", value: "CONCLUSION" },
];

export default function MainFeed() {
  const { isMobile } = useZekToolkit();
  const [categoryType, setCategoryType] = useState("LATEST");
  const [type, setType] = useState("DISCUSSION");

  let options = [
    { label: "Latest", value: "LATEST" },
    { label: "Following", value: "FOLLOWING" },
  ];

  options = [...options, ...contentCategories];

  let toggleStyle = {
    flexWrap: "nowrap",
  };

  if (window.innerWidth < 1300) {
    //13inch laptop
    toggleStyle.flexWrap = "wrap";
    toggleStyle.width = "62vw";
  }

  if (isMobile) toggleStyle.width = "100%";

  let categorySelector = (
    <CustomToggle
      value={categoryType}
      onChange={setCategoryType}
      options={options}
      colorVariation={"SURFACE"}
    />
  );

  let typeSelector = (
    <CustomToggle
      value={type}
      onChange={setType}
      options={typeOptions}
      colorVariation={"SURFACE"}
      btnStyle={{ borderRadius: 0 }}
      onHoverStyle="transform: scale(1)"
      toggleStyle={{
        // background: "var(--secondarySurface)",
        borderRadius: "10px",
        justifyContent: "space-between",
        padding: "0",
        overflow: "hidden",
        gap: "1px",
        // minWidth: "240px",
      }}
    />
  );

  if (isMobile) {
    categorySelector = (
      <DropDownInput
        disableSearch={true}
        value={categoryType}
        onChange={setCategoryType}
        options={options}
        colorVariation={"SURFACE"}
      />
    );

    typeSelector = (
      <DropDownInput
        disableSearch={true}
        value={type}
        onChange={setType}
        options={typeOptions}
        colorVariation={"SURFACE"}
      />
    );
  }

  let optionRow = (
    <Row>
      {typeSelector}
      {categorySelector}
    </Row>
  );

  return (
    <Container>
      {optionRow}

      <ContentFeed type={type} categoryType={categoryType} />

      <br />
      <br />
      <br />
    </Container>
  );
}

// function getOppositeGenderName(gender) {
//   if (gender == "MALE") return "From Gals";
//   if (gender == "FEMALE") return "From Boys";
//   if (gender == "LESBIAN") return "From Lesbians";
//   if (gender == "GAY") return "From Gays";
// }

import pathVsComp from "../data/pathVsComp";
import ZekToolkit from "../zekFrontendToolkit/ZekToolkit";
import RouteEncapsulation from "./RouteEncapsulation";

export default function Configure() {
  let muiTheme = {
    palette: {
      mode: "dark",
      primary: {
        main: "#5c8ada",
        dark: "#5c8ada",
      },
      secondary: {
        main: "#5c8ada",
      },
    },
  };

  let darkTheme = {
    //Background
    popupBackground: "linear-gradient(rgba(3,3,3), rgba(10,10,10))",
    mainBackground: "linear-gradient(rgba(3,3,3), rgba(10,10,10))",
    mainBackgroundText: "#ffffff",

    translucent: "rgba(255,255,255,0.1)",
    translucentLight: "rgba(255,255,255,0.05)",
    translucentHard: "rgba(255,255,255,0.1)",
    //Surface
    primarySurface: "rgb(25 25 29)", //Main cards & panels
    secondarySurface: "rgba(17,17,17)", //Secondary cards like chat group not selected
    surfaceText: "#ffffff",

    //Buttons
    superPrimaryInput: "rgba(10,10,0)", // Main buttons
    primaryInput: "rgba(0,0,0)", // Main buttons
    secondaryInput: "rgba(14,14,14)", // Main buttons
    inputText: "#ffffff",

    bgColor: "#111",
    color: "#c4d6f6",

    //Others
    accentColor: "#a0b5da",
    accentLightColor: "#B7D1FB",
    mobileHeaderBorder: "#222222",
    borderRadius: "13px",
    logo: "url('/logo/dark-mode/logo.svg')",
    textLogo: "url('/logo/dark-mode/text-logo.svg')",
    lightShadow: "1px 10px 100px 0 rgba(0,0,0,0.2)",
  };

  let lightTheme = {
    //Background
    popupBackground: "linear-gradient(#c4d6f6, #a0a9d7)",
    mainBackground: "linear-gradient(#DDE6F6, #C2CAF1)",
    mainBackgroundText: "#153E87",

    bgColor: "#ffffff",
    color: "rgb(26, 31, 53)",

    translucent: "rgb(218 232 255)",
    translucentLight: "rgb(42 122 182 / 10%)",
    translucentHard: "rgb(42 122 182 / 20%)",
    //Surface
    primarySurface: "#ffffff", //Main cards & panels
    secondarySurface: "rgba(255,255,255,0.5)", //Secondary cards like chat group not selected
    surfaceText: "#153E87",

    //Buttons
    superPrimaryInput: "#8fbaff", // Main buttons
    primaryInput: "#B7D1FB", // Main buttons
    secondaryInput: "#DBE8FF", // Main buttons
    inputText: "#153E87",

    //Others
    accentLightColor: "#5c8ada",
    accentColor: "#5c8ada",
    mobileHeaderBorder: "#9fbae68f",
    borderRadius: "13px",
    logo: "url('/logo/light-mode/logo.svg')",
    textLogo: "url('/logo/light-mode/text-logo.svg')",
    lightShadow: "1px 10px 40px 0 rgba(0,0,0,0.2)",
  };

  return (
    <ZekToolkit
      googleAuthClientID={
        "991911447615-vkp6b69shojc7jk2evhdjcdnaqosabnr.apps.googleusercontent.com"
      }
      routes={pathVsComp}
      localServerURL={"http://localhost:8080"}
      productionServerURL={"https://cottonchat.com"}
      muiTheme={muiTheme}
      darkTheme={darkTheme}
      lightTheme={lightTheme}
      defaultColorMode={"LIGHT"}
      namespace={"cottonchat"}
      RouteEncapsulation={RouteEncapsulation}
    />
  );
}

function getValue(dob) {
  //If in the current year's month had crossed DOB's month then current year will be counted otherwise not

  let birthday = new Date(dob);
  let today = new Date();

  if (today.getMonth() > birthday.getMonth()) {
    return considerCurrentYear();
  } else if (today.getMonth() == birthday.getMonth()) {
    if (today.getDate() >= birthday.getDate()) {
      return considerCurrentYear();
    } else {
      return dontConsiderCurrentYear();
    }
  } else {
    return dontConsiderCurrentYear();
  }

  function considerCurrentYear() {
    let diff = today.getFullYear() - birthday.getFullYear();
    return diff;
  }

  function dontConsiderCurrentYear() {
    let diff = today.getFullYear() - birthday.getFullYear();
    return diff - 1;
  }
}

export default function calculateAge(dob) {
  let value = getValue(dob);

  if (value) return `${value} years old`;

  return "";
}

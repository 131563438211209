import { useContext, useEffect } from "react";

import { styled } from "styled-components";

import ChatGroupsPanel from "./components/chatGroupsPanel/ChatGroupsPanel";
import InfoBox from "../../util/components/InfoBox";
import loadSelectedChatGroup from "./controllers/api/loadSelectedChatGroup";
import PopupLogin from "../../util/components/core/PopupLogin";
import ChatMainPanel from "./components/chatMainPanel/ChatMainPanel";
import useZekToolkit from "../../zekFrontendToolkit/useZekToolkit";
import Context from "../../Context";
import AnimatedPage from "../../zekFrontendToolkit/ui/animation/AnimatedPage";

const Container = styled.div`
  display: grid;
  grid-template-columns: 0.7fr 3fr;
  /* height: calc(100vh - var(--mainPadding) - var(--mainPadding)); */
  /* height: calc(100svh - var(--mainPadding) - var(--mainPadding)); */

  height: calc(100% - var(--mainPadding));
  width: calc(100% - var(--mainPadding));
  width: 100%;
  /* margin-right: var(--mainPadding); */
  background: transparent;
  border-radius: 10px;
  gap: 0;
  overflow: hidden;

  @media (max-width: 1300px) {
    // For ipad and 13inch macbooks
    grid-template-columns: 1.5fr 3fr;
  }

  @media (max-width: 900px) {
    padding: 0;
    grid-template-columns: 1fr;
    height: auto;
    width: 100%;
    margin: 0;
    overflow: visible;
    box-shadow: var(--hardShadow);
  }
`;

export default function ChatPage({ pathSegments }) {
  const { loggedInUser, loggedInUserID } = useZekToolkit();

  const { globalChatSpace } = useContext(Context);

  let {
    setSelectedChatGroupData,
    setSelectedChatGroupID,
    isConnected,
    selectedChatGroupID,
  } = globalChatSpace;

  let chatGroupID = null;

  if (pathSegments.length >= 2) {
    chatGroupID = pathSegments[0];
  }

  console.log("selectedChatGroupID", selectedChatGroupID, chatGroupID);

  useEffect(() => {
    if (!chatGroupID) {
      console.log("chat group does not exists");
      setSelectedChatGroupID(null);
      setSelectedChatGroupData(null);
    } else {
      console.log("chat group id exists");
      setSelectedChatGroupID(chatGroupID);
    }

    loadSelectedChatGroup(chatGroupID);
  }, [chatGroupID]);

  useEffect(() => {
    return removeSelectedChatGroup;
  }, []);

  if (!loggedInUserID) return <PopupLogin />;

  if (!isConnected)
    return <InfoBox>Connection lost, trying to reconnect....</InfoBox>;

  if (loggedInUser) {
    if (loggedInUser.me.isBanned)
      return (
        <InfoBox>
          You can't access this page because you have been banned.
        </InfoBox>
      );
  }

  return (
    <AnimatedPage>
      <Container>
        <ChatGroupsPanel />
        <ChatMainPanel />
      </Container>
    </AnimatedPage>
  );

  function removeSelectedChatGroup() {
    console.log("cleaning ids...");
    setSelectedChatGroupData(null);
    setSelectedChatGroupID(null);
  }
}

import styled from "styled-components";
import { useContext } from "react";
import Context from "../../../Context.js";
import useZekToolkit from "../../../zekFrontendToolkit/useZekToolkit.js";

const TopPart = styled.div`
  display: flex;
  flex-direction: row;
  width: 90vw;
  justify-content: space-between;
  padding-top: 50px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  top: 0;
  position: sticky;
  z-index: 100;
  background-color: var(--rootBackgroundColor);

  @media (max-width: 900px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

const Links = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  flex-direction: row;
  justify-content: space-between;
  /* gap: 40px; */
  align-items: center;
  /* padding: 0px 35px; */
  /* border-radius: 10px; */
  /* height: 70px; */
  /* background: #161716; */
`;

const Right = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const TopPartMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
`;

const RowMobile = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const Button = styled.div`
  font-size: 20px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(0px);
  color: var(--color);
  opacity: 0.8;
  height: 60px;
  transition: all 0.25s ease-in-out;
  position: relative;

  ${({ isActive }) => {
    if (isActive) {
      return `
     
      opacity:1;
      `;
    }
  }}

  /* Create the ripple using a pseudo-element */
   &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(44, 63, 112, 0.14);
    border-radius: 300px;
    top: 0;
    left: 0;
    transform: scale(0.5);
    opacity: 0;
    pointer-events: none;
    transition: transform 0.15s ease-in-out, opacity 0.15s ease-in-out;
  }

  /* On active state, animate the ripple */
  &:active::after {
    transform: scale(0.7);
    opacity: 1;
    transition: 0.1s;
  }
`;

const Main = styled.div`
  position: relative;
`;

const LeftPart = styled.div`
  background-color: red;
  background-color: var(--navBarBg);
  left: 0;
  bottom: 0;
  position: absolute;
  z-index: 99;
  height: 70px;
  width: 170px;
  border-radius: 0 20px 0 0;
  z-index: -1;
  pointer-events: none;
  transition: all 0.25s ease-in-out;
`;

const RightPart = styled.div`
  background-color: red;
  background-color: var(--navBarBg);
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 99;
  height: 70px;
  width: 170px;
  border-radius: 20px 0 0 0;
  z-index: -1;
  pointer-events: none;
  transition: all 0.25s ease-in-out;
`;

const Circle = styled.div`
  position: absolute;
  left: 0;

  z-index: -1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  transition: all 0.25s ease-in-out;
`;

const CircleMain = styled.div`
  width: 100%;
  height: 60px;
  width: 100%;
  background-color: var(--primaryInput);
  border-radius: 50px;
`;

const NotificationBubble = styled.div`
  display: flex;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 10px;
  padding: 0 7px;
  height: 20px;
  font-weight: 900;
  /* top: 15px; */
  padding: 0;
  border-radius: 500px;
  height: 25px;
  padding: 0 10px;
  width: auto;
  background-color: var(--accentColor);
  color: #111;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
`;

const exemptedRoutes = [];

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
`;

export default function NavWithAnimatedHighlight({
  options,
  widthInPercentage,
}) {
  const { currentRoute } = useZekToolkit();

  let currentPageIndexAndType = getCurrentPageIndexAndType();

  for (let item of exemptedRoutes) {
    if (currentRoute.indexOf(item) !== -1) return null;
  }

  let items = [];

  for (let item of options) {
    items.push(
      <Button
        key={item.name}
        onClick={window.goTo(item.path)}
        isActive={checkIsActive(item)}
      >
        {item.icon}

        {item.bubble ? (
          <NotificationBubble>{item.bubble}</NotificationBubble>
        ) : null}
      </Button>
    );
  }

  return (
    <Container>
      <Links style={{ width: widthInPercentage + "%" }}>
        {items}

        {getAnimatedHighlighter()}
      </Links>{" "}
    </Container>
  );

  function getAnimatedHighlighter() {
    if (!currentPageIndexAndType) return null;

    let highlightConfig = getHighlighterConfig(currentPageIndexAndType);

    return (
      <Circle style={highlightConfig}>
        <CircleMain />
      </Circle>
    );
  }

  function checkIsActive(item) {
    if (!currentPageIndexAndType) return false;
    if (item.name == currentPageIndexAndType.type) return true;
    return false;
  }

  function getCurrentPageIndexAndType() {
    let theType = currentRoute.split("/")[1];
    if (!theType) {
      return { index: 0, type: options[0].name };
    }

    let i = 0;

    for (let item of options) {
      if (item.path == `/${theType}`) {
        return { index: i, type: item.name };
      }
      i++;
    }

    return null;
  }

  function getHighlighterConfig({ index }) {
    let totalItems = options.length;

    let totalWidth = window.innerWidth * (widthInPercentage / 100);
    let itemWidth = totalWidth / totalItems;

    return {
      width: itemWidth * 1 + "px",
      height: itemWidth * 0.5 + "px",
      left: itemWidth * index + "px",
    };
  }
}

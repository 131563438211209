import styled from "styled-components";
import { HiMenuAlt4 } from "react-icons/hi";
import BrandContainer from "../../../util/components/brand/BrandContainer.js";
import MainFeed from "./feed/MainFeed.js";
import FilledButton from "../../../util/components/buttons/FilledButton.js";
import Options from "../../../util/components/inputs/Options.js";
import LoginWithGoogleBtn from "./LoginWithGoogleBtn.js";
import useZekToolkit from "../../../zekFrontendToolkit/useZekToolkit.js";
import { useState } from "react";

const ParentContainer = styled.div`
  display: flex;
  height: 100vh;
  overflow-y: scroll;
  width: 100vw;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 60px 60px;

  background: var(--primarySurface);

  gap: 120px;
  border-radius: 10px;
  width: 100%;
  padding-bottom: 0;
  /* border-radius: 0 20px 20px 0; */

  font-weight: 700;
  font-family: "Raleway", sans-serif;
  /* width: 100vw; */
  box-shadow: 0px 5px 0px 1px var(--accentLightColor);

  @media (max-width: 900px) {
    width: 100%;
    padding: 30px 10px;
    gap: 50px;
    padding-bottom: 0;

    justify-content: space-between;
  }
`;

const Tagline = styled.div`
  font-weight: 900;
  font-size: 45px;
  text-align: center;
  font-family: "Staatliches", sans-serif;

  @media (max-width: 900px) {
    font-size: 22px;
    width: 90%;
  }
`;
const MainButtons = styled.div`
  /* margin-top: 50px; */
  display: flex;
  flex-direction: row;
  gap: 15px;
  height: 60px;
  align-items: center;
  border: none;
  justify-content: center;
  width: 100%;

  @media (max-width: 900px) {
    width: 100%;
    height: auto;

    margin-bottom: 0px;
    justify-content: center;
    /* justify-content: center; */
    /* flex-direction: column; */
  }
`;

const Medium = styled.div`
  font-size: 16px;
  opacity: 0.8;
  text-align: justify;
  width: 38vw;
  font-weight: 600;
  text-align: center;
  opacity: 0.5;
  line-height: 25px;
  font-family: "Montserrat", sans-serif;
  text-align: justify;
  text-justify: inter-word;

  @media (max-width: 900px) {
    width: 90%;
    font-size: 13px;
    line-height: 19px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 19px;
  /* margin-top: 90px; */
  width: 45vw;

  @media (max-width: 900px) {
    width: 100%;
    /* margin-top: 20px; */
  }
`;

const BottomDecoration = styled.img`
  margin-bottom: -10px;

  @media (max-width: 900px) {
    /* display: none; */
    width: 100%;
    height: 60px;
    margin-top: 20px;
    object-fit: cover;
    margin-bottom: 0px;
  }
`;

const btnStyle = {
  padding: "15px 30px",
  background: "transparent",
  color: "var(--accentColor)",
  border: "1px solid var(--accentColor)",
  boxShadow: "0px 3px 0px 1px var(--accentColor)",
};

let moreButtonStyle = {
  ...btnStyle,
  padding: "15px 20px",
};

const MediumTitle = styled.b`
  margin-bottom: -5px;
  margin-top: 5px;
  display: block;
`;

const ReadMoreButton = styled.button`
  background: transparent;
  border: none;
  color: var(--accentColor);
  cursor: pointer;
  opacity: 0.7;
  /* text-decoration: underline; */
  font-weight: bold;
  margin-top: -20px;
`;

export default function LoggedOutHomePage() {
  const { showPopup } = useZekToolkit();
  const [expanded, setExpanded] = useState(false);

  return (
    <ParentContainer>
      <Main>
        <BrandContainer />
        <TextContainer>
          <Tagline>Discuss, Debate & Make New Friends</Tagline>
          <Medium>
            <p>
              When you share an opinion, idea, or question, it becomes a
              discussion. Each discussion can have multiple private chat rooms,
              and each chat room can have up to five members. Smaller groups
              foster deeper discussions.
            </p>

            <p>
              In a chat room, members privately debate their ideas. At the end
              of the discussion, each member submits a conclusion, which is then
              made public, and votes are cast on the conclusions.
            </p>

            <p>
              You also get two accounts—one public and one anonymous—so you can
              express yourself freely without fear of personal scrutiny.
            </p>

            {expanded ? (
              <>
                <MediumTitle>Why does this matter?</MediumTitle>
                <p>
                  Because each chat room is limited to a maximum of five
                  members, it becomes easier to convey your opinions and
                  understand others' perspectives. By keeping discussions small,
                  we ensure that every participant’s voice matters while
                  reducing the risk of mass scrutiny. This allows for more open
                  and meaningful conversations, even on sensitive topics.
                </p>

                <p>
                  This setup mirrors how we interact in real life. At school,
                  college, or work, we naturally form small groups where
                  discussions unfold privately. These conversations take
                  different turns—sometimes they teach us something new,
                  sometimes they inspire us, and sometimes they annoys us—but
                  they ultimately help us connect and form friendships.
                </p>

                <p>
                  Now, contrast this with how discussions typically happen
                  online. Someone shouts an opinion to millions, a thousand
                  people comment, and yet, no one truly gets to know the
                  reasoning behind each other's opinion. It’s counterproductive
                  and often toxic.
                </p>

                <p>
                  Think of it this way: most social networks are like big
                  cities—crowded and noisy, yet isolating. Everyone is confined
                  to their own carefully curated space. CottonChat, on the other
                  hand, is like a village. In a village, everyone knows each
                  other, and because the community is small, individuals receive
                  more value and respect. This creates a more meaningful and
                  positive environment for discussions.
                </p>
              </>
            ) : null}
          </Medium>
          {!expanded && (
            <ReadMoreButton onClick={() => setExpanded(true)}>
              Read More
            </ReadMoreButton>
          )}
          <br />

          <MainButtons>
            <LoginWithGoogleBtn />
            <FilledButton
              style={moreButtonStyle}
              onClick={openMoreMenu}
              icon={<HiMenuAlt4 />}
            ></FilledButton>
          </MainButtons>
        </TextContainer>

        <BottomDecoration src="/decorations/bottom-decoration.png" />
      </Main>
      <br /> <br /> <br />
      <MainFeed />
    </ParentContainer>
  );

  function open(link) {
    return () => {
      return (window.location = link);
    };
  }

  function openMoreMenu() {
    let options = [
      {
        label: "About us",
        onClick: open("https://arnavzek.com"),
      },
    ];

    showPopup({
      title: "Options",
      component: <Options options={options} />,
    });
  }
}

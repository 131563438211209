import styled from "styled-components";
import ChatGroupBox from "./ChatGroupBox";

import LoadingSection from "../../../../util/components/LoadingSection";
import { useContext, useEffect, useState } from "react";
import loadChatGroups from "../../controllers/api/loadChatGroups";
import CustomToggle from "../../../../util/components/inputs/CustomToggle";
import Context from "../../../../Context";
import { AnimatePresence, motion } from "framer-motion";
import ChatGroupsShimmer from "../../../../util/components/shimmer/ChatGroupsShimmer";

const Info = styled.div`
  width: 100%;
  padding: 20px;
`;

const LeftPanel = styled.div`
  height: 100%;
  width: 100%;
  padding: 0;
  padding-top: 0;
  overflow: visible;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 900px) {
    border: none;
    ${({ $selectedChatGroupID }) => {
      if ($selectedChatGroupID) return `display:none;`;
    }}
  }

  @media (max-width: 900px) {
    overflow: visible;
    padding-bottom: 300px;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Top = styled.div``;

let typeOptions = [
  { value: "GENERAL", label: "General" },
  { value: "MUTED", label: "Muted" },
];

export default function ChatGroupsPanel() {
  const { globalChatSpace } = useContext(Context);

  let {
    chatGroupsType,
    setChatGroupsType,
    mutedChatGroups,
    chatGroupsAreLoading,
    chatGroups,
    selectedChatGroupID,
    friendsLastSeenAt,
  } = globalChatSpace;

  useEffect(() => {
    loadChatGroups();
  }, [chatGroupsType]);

  let topPart = (
    <Top>
      <CustomToggle
        toggleStyle={{ padding: 0, gap: "10px" }}
        btnStyle={{ flex: 1, borderRadius: "10px" }}
        colorVariation={"SURFACE"}
        options={typeOptions}
        value={chatGroupsType}
        onChange={setChatGroupsType}
      />
    </Top>
  );

  if (chatGroupsAreLoading)
    return (
      <LeftPanel $selectedChatGroupID={selectedChatGroupID}>
        {topPart}
        <ChatGroupsShimmer />
      </LeftPanel>
    );

  let theList = chatGroups;

  if (chatGroupsType == "MUTED") theList = mutedChatGroups;

  let groupBoxes = theList.map((item, index) => (
    <motion.div
      layout
      key={item._id}
      layoutId={String(item._id)}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3, delay: index * 0.1 }}
      className="p-2 bg-gray-200 rounded"
    >
      <ChatGroupBox
        key={item._id}
        highlight={selectedChatGroupID == item._id}
        friendsLastSeenAt={friendsLastSeenAt}
        item={item}
        onClick={selectNewGroup}
      />
    </motion.div>
  ));

  return (
    <LeftPanel
      className="chat-groups-panel"
      $selectedChatGroupID={selectedChatGroupID}
    >
      {topPart}
      <List>
        <AnimatePresence>
          {groupBoxes.length ? (
            groupBoxes
          ) : (
            <Info> New Chats Groups Will Appear Here</Info>
          )}
        </AnimatePresence>
      </List>
    </LeftPanel>
  );

  function selectNewGroup(chatGroup) {
    let gotoOptions = null;
    if (selectedChatGroupID) {
      gotoOptions = { isReplace: true };
    }

    window.goTo("/chat/" + chatGroup._id, gotoOptions)();
  }
}

import { styled } from "styled-components";
import { useEffect, useState } from "react";
import selectFile from "../../controllers/selectFile.js";
import Context from "../../../Context.js";
import { AiOutlineCheck, AiOutlineUpload } from "react-icons/ai";
// import ImageCropper2 from "../ImageCropper2.js";
import DropDownLocationInput from "../DropDownLocationInput.js";
import CustomDateInput from "../inputs/CustomDateInput.js";
import LoadingSection from "../LoadingSection.js";
import MaterialInput from "../MaterialInput.js";
import ShowSelectedImage from "../ShowSelectedImage.js";
import CustomToggle from "../inputs/CustomToggle.js";
import ProfilePicture from "../ProfilePicture.js";
import FilledButton from "../buttons/FilledButton.js";
import SocialLinksEditor from "../profile/SocialLinksEditor.js";
import useZekToolkit from "../../../zekFrontendToolkit/useZekToolkit.js";
import serverLine from "../../../zekFrontendToolkit/network/serverLine.js";
import compressAndUploadFile from "../../../zekFrontendToolkit/mediaUpload/controller/compressAndUploadFile.js";
import { BsDice2 } from "react-icons/bs";
// import PopupEmojiSelector from "../PopupEmojiSelector.js";
import getRandomLivingThingEmoji from "../../controllers/getRandomLivingThingEmoji.js";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0;
  width: 100%;

  gap: 10px;
  align-items: center;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  gap: 20px;
  justify-content: center;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const Button = styled.div`
  width: 100%;
  font-size: 15px;
  border: 1px solid var(--translucent);
  background: var(--secondaryInput);
  border-radius: 10px;
  color: var(--color);
  text-align: center;
  cursor: pointer;
  padding: 10px 5px;
`;

const ImageUploadSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 25px;
  width: 100%;

  justify-content: center;
  align-items: center;
`;

const ImageUploadButton = styled(Button)`
  /* width: 300px; */

  width: 15vw;

  @media (max-width: 900px) {
    width: calc((90vw - 130px) / 2);
  }
`;

const PickRandomEmoji = styled(Button)`
  /* width: 300px; */

  width: 50px;
`;

const ImageUploadButtons = styled.div`
  /* width: 300px; */

  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  justify-content: center;
`;

export default function BasicInfoEditor() {
  const { loggedInUser, refreshUser, popupAlert, showPopup } =
    useZekToolkit(Context);

  const [config, setConfig] = useState({});
  const [selectedImages, setSelectedImages] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loggedInUser) {
      let newState = { ...config };
      newState = { ...loggedInUser.me };

      if (!newState.profileImage) {
        newState.profileImage = {
          type: "EMOJI",
          data: getRandomLivingThingEmoji(),
        };
      }
      setConfig(newState);
    }
  }, [loggedInUser]);

  if (loading || !loggedInUser) return <LoadingSection />;

  let profilePicObj = selectedImages ? (
    <ShowSelectedImage
      height={"130px"}
      width={"130px"}
      fileObject={selectedImages[0]}
    />
  ) : (
    <ProfilePicture size="130px" data={config.profileImage} />
  );

  return (
    <Container>
      <Inputs>
        <ImageUploadSection>
          {profilePicObj}

          <ImageUploadButtons>
            <ImageUploadButton onClick={chooseImage}>
              {selectedImages ? "Change" : "Upload"}
            </ImageUploadButton>

            {/* <ImageUploadButton onClick={doEmojiPickerPopup}>
              Select Emoji
            </ImageUploadButton> */}
            <PickRandomEmoji onClick={pickRandomEmoji}>
              <BsDice2 />
            </PickRandomEmoji>
          </ImageUploadButtons>
        </ImageUploadSection>

        <MaterialInput
          value={config.name}
          onChange={makeChange("name")}
          label={"Full Name"}
        />

        <MaterialInput
          value={config.username}
          onChange={makeChange("username")}
          label={"Username"}
        />

        <MaterialInput
          value={config.bio}
          multiline={true}
          rows={4}
          onChange={makeChange("bio")}
          label={"Describe yourself..."}
        />

        <CustomDateInput
          type="date"
          value={config.dateOfBirth}
          onChange={makeChange("dateOfBirth", true)}
          label={"Birthday"}
        />
        <CustomToggle
          name="Gender"
          options={[
            { label: "Male", value: "MALE" },
            { label: "Female", value: "FEMALE" },
            { label: "Other", value: "OTHER" },
          ]}
          value={config.gender}
          onChange={makeChange("gender", true)}
        />

        <>
          <SocialLinksEditor
            value={config.socialLinks}
            onChange={makeChange("socialLinks", true)}
          />
          <DropDownLocationInput
            onChange={makeChange("country", true)}
            type="COUNTRY"
            placeholder={""}
            value={config.country}
          />
        </>
      </Inputs>
      <br /> <br />
      <FilledButton
        style={{ width: "200px", padding: "15px 0" }}
        icon={<AiOutlineCheck />}
        onClick={save}
        variant="filled"
      >
        Save
      </FilledButton>
      <br /> <br />
    </Container>
  );

  function pickRandomEmoji() {
    setSelectedImages(null);
    let newConfig = { ...config };
    newConfig.profileImage = {
      type: "EMOJI",
      data: getRandomLivingThingEmoji(),
    };
    setConfig(newConfig);
  }

  // function assignEmojiAsProfile(theEmoji) {
  //   setSelectedImages(null);

  //   let newConfig = { ...config };
  //   newConfig.profileImage = { type: "EMOJI", data: theEmoji };
  //   setConfig(newConfig);

  //   window.goTo(-1)();
  // }

  // function doEmojiPickerPopup() {
  //   showPopup({
  //     component: <PopupEmojiSelector onChange={assignEmojiAsProfile} />,
  //   });
  // }

  function makeChange(fieldName, isNotEvent) {
    return (e) => {
      if (isNotEvent) {
        let newItem = { ...config };
        newItem[fieldName] = e;
        setConfig(newItem);
        return true;
      }

      let newItem = { ...config };
      newItem[fieldName] = e.target.value;
      setConfig(newItem);
    };
  }

  async function chooseImage() {
    let selectedFiles = await selectFile({ onlyImage: true });

    if (selectedFiles) {
      if (selectedFiles.length) {
        setSelectedImages(selectedFiles);

        // showPopup({
        //   title: "Crop Image",
        //   component: (
        //     <ImageCropper2
        //       imageBlob={selectedFiles[0]}
        //       onCropDone={(newImage) => {
        //         window.goTo(-1)();
        //         setSelectedImages([newImage]);
        //       }}
        //     />
        //   ),
        // });
      }
    }
  }

  async function uploadImage() {
    if (!selectedImages) return null;
    if (!selectedImages.length) return null;
    setLoading(true);
    // console.log("Uploading Image");
    let newFile = await compressAndUploadFile({
      selectedFile: selectedImages[0],
      folder: "profile-image",
    });

    setLoading(false);
    return newFile;
  }

  function isAlphaNumericWithOrWithoutHyphen(str) {
    return /^[a-zA-Z0-9-]+$/.test(str);
  }

  async function save() {
    // if (!config.bio) return popupAlert("Bio is missing");
    // if (!config.country) return popupAlert("Please add your country");

    if (!config.dateOfBirth) return popupAlert("Date of birth is required.");

    if (config.username) {
      if (!isAlphaNumericWithOrWithoutHyphen(config.username)) {
        return popupAlert(
          "The username can only contain alphabets and numbers"
        );
      }
    }

    // if (!userIsAdult(config.dateOfBirth))
    //   return popupAlert("Sorry! You need to 18 Years old to use CottonChat");

    try {
      let fileData = await uploadImage();

      let toSend = {
        changes: {},
      };

      toSend.changes.onboarding = "basicInfoAdded";
      toSend.changes.name = config.name;
      toSend.changes.bio = config.bio;
      toSend.changes.gender = config.gender;

      toSend.changes.dateOfBirth = config.dateOfBirth;

      toSend.changes.socialLinks = config.socialLinks;

      toSend.changes.username = config.username;
      toSend.changes.profileImage = config.profileImage;
      toSend.changes.country = config.country;

      let newConfig = { ...config };

      if (fileData) {
        if (fileData.fileName) {
          toSend.changes.profileImage = {
            type: "S3_UPLOAD",
            data: fileData.fileName,
          };

          newConfig.profileImage = toSend.changes.profileImage;
        }
      }

      setLoading(true);

      await serverLine.patch("/profile", toSend);
      await refreshUser();

      setLoading(false);

      popupAlert("Saved!");
    } catch (e) {
      setLoading(false);
      popupAlert(e.message);
    }
  }
}

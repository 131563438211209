import styled from "styled-components";
import { MoonLoader } from "react-spinners";
import { Button } from "@mui/material";
import useZekToolkit from "../useZekToolkit.js";

const Text = styled.div`
  /* text-transform: lowercase; */
  font-weight: 800;
  display: flex;

  font-family: "Montserrat", sans-serif;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 13px;
  color: var(--color);

  @media (max-width: 900px) {
    font-size: 11px;
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  color: var(--color);
  align-items: center;
`;

export default function ZekButton({
  children,
  icon,
  onClick,
  href,
  style,
  variant,
  isLoading,
  isActive,
  onActiveStyle,
  disabled,
  onlyDesktop,
  customVariant,
  textStyle,
  iconStyle,
}) {
  const { isMobile, goTo } = useZekToolkit();

  if (onlyDesktop) {
    if (isMobile) return null;
  }
  if (href && !onClick) {
    onClick = () => {
      goTo(href)();
    };
  }

  if (!variant && !customVariant) {
    customVariant = "slightlyContained";
  }

  if (!style) style = {};

  if (disabled) {
    style = { ...style, opacity: 0.5, pointerEvents: "none" };
  }

  if (isActive && onActiveStyle) {
    style = { ...style, ...onActiveStyle };
  }

  if (isLoading) icon = <MoonLoader size="20px" color="var(--color)" />;

  if (icon) icon = <Icon style={iconStyle}>{icon}</Icon>;

  let additionalStyle = {};
  if (!children) {
    if (icon) {
      children = icon;
      icon = null;
      additionalStyle = { height: "60px", width: "60px", padding: 0 };
    }
  } else {
    children = <Text style={textStyle}>{children}</Text>;
  }

  if (customVariant) {
    if (customVariant == "slightlyContained") {
      additionalStyle = {
        background: `var(--translucentLight)`,
        border: `1px solid var(--translucent)`,
        ...additionalStyle,
      };
    }
  }

  return (
    <Button
      onClick={theClick}
      style={{
        minWidth: "unset", //Material UI has a min width that becomes a problem when setting width
        padding: "10px 20px",
        gap: "10px",
        borderRadius: "50px",
        boxShadow: "rgb(120 166 0 / 72%) 1px 5ppx 50px 1px",
        ...additionalStyle,
        ...style,
      }}
      variant={variant}
      startIcon={icon}
    >
      {children}
    </Button>
  );

  function theClick(e) {
    e.preventDefault();
    if (onClick) onClick(e);
  }
}

export default function reachChatPanelEnd() {
  // theFunction();

  window.setTimeout(theFunction, 100);
}

function theFunction() {
  // return;
  const element = document.getElementById("chat-panel");
  if (!element) return;

  element.scrollTo({ top: element.scrollHeight, behavior: "smooth" });

  // element.scrollTop = element.scrollHeight;
}
